import React from 'react';
import AuthenticatedMenuBar from '../containers/Genesis/Menu/AuthenticatedMenuBar';
import menuPropsCN from 'lib/menuPropsCN';
import { styled } from '@ff/ui';
import Box from '@ff/ui/Box';
import { isAppOnly } from '~/app/utils/pathUtils';
import Icon from '@ff/ui/Icon';
// import { closeWebview } from '~/shared/utils/appHandler';

const MENU_PROPS = window?.MENU_PROPS || menuPropsCN;

export default function ReferralLandingLayout({children}){
  const isApp = isAppOnly();

  const handleCloseClick = () => {
    // to do
    // closeWebview();
  };

  return isApp
    ?
    <>
      <H5Header>
        <CloseIcon name='ff-icon-clear' fontSize='28px' onClick={handleCloseClick} />
      </H5Header>
      <main>
        {children}
      </main>
    </>
    : 
    <>
      <AuthenticatedMenuBar
        {...MENU_PROPS}
        colorTheme='DARK'
        float={true}
        spacer={false}
        backgroundColor='transparent'
      />
      <main>
        {children}
      </main>
    </>
}

const CloseIcon = styled(Icon)({
  color: 'white',
  display: 'block',
  lineHeight: '30px',
  height: '30px',
  marginTop: '8px',
  marginLeft: '8px',
});

const H5Header = styled(Box)(({ theme }) => ({
  height: '46px',
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: theme.zIndices.menubar
}));