/* eslint-disable no-underscore-dangle */
import translations from './locales';

const getRegion = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname.split('/')[1].toUpperCase();
  }

  return 'CN';
};

export function getTextForLang(key, lang, params={}) {
  const trans = translations[key];

  if (!trans) {
    return key;
  }

  let text = trans[lang];
  if(!text) {
    return text;
  }

  /* example:
   * text: "A value is {a}, b value is {b:=0}, d value is {d:=4}"
   * params: {a: 1, b: 2, c: 3}
   * result: "A value is 1, b value is 2, d value is 4"
  */
  // Lookbehind is not supported in recent versions of Safari
  // https://caniuse.com/js-regexp-lookbehind
  let paramDefs = (text.match(/{[^{}]+}/g) || []).map(x => x.replace(/[{}]/g, ''))
  for (const paramDef of paramDefs) {
    const tmp = paramDef.split(':=');
    const name = tmp[0];
    const defValue = tmp[1];
    const value = params[name] !== undefined && params[name] !== null ? params[name] : defValue;

    text = text.replace(`{${paramDef}}`, value);
  }

  return text;
}

/**
 * Isomorphic gettext
 * @param {String} key key of string.
 * @return {String}
 */
export default function gettext(key, params={}) {
  let lang = 'en';
  const region = getRegion();

  switch (region) {
    case 'CN':
      lang = 'zh';
      break;
    default:
      lang = 'en';
      break;
  }

  return getTextForLang(key, lang, params);
}
