function getEnv(key) {
  if (typeof window === 'undefined') return undefined;

  if(!window._env_) {
    console.warn('window._env_ is not defined');
    return undefined;
  }

  return window._env_[key];
}

export const HIDE_NEW_DASHBOARD = getEnv('REACT_APP_HIDE_NEW_DASHBOARD') === 'true';
export const ENABLE_NEW_DESIGN = getEnv('REACT_APP_ENABLE_NEW_DESIGN') === 'true';
export const ENABLE_NEW_LOGIN = getEnv('REACT_APP_ENABLE_NEW_LOGIN') === 'true';
export const ENABLE_NEW_REFERRAL = getEnv('REACT_APP_ENABLE_NEW_REFERRAL') === 'true';
export const ENABLE_NEW_ECOSYSTEM_PRODUCT = getEnv('REACT_APP_ENABLE_NEW_ECOSYSTEM_PRODUCT') === 'true';
export const ENABLE_FF91_PREORDER = getEnv('REACT_APP_ENABLE_FF91_PREORDER') === 'true';
export const ENABLE_2_0_TEXT = getEnv('REACT_APP_ENABLE_2_0_TEXT') === 'true';
export const ENABLE_H5_UPGRADE = getEnv('REACT_APP_ENABLE_H5_UPGRADE') === 'true';
export const ENABLE_H5_COMING_SOON = getEnv('REACT_APP_ENABLE_H5_COMING_SOON') === 'true';
export const REACT_APP_CDN_DOMAIN = getEnv('REACT_APP_CDN_DOMAIN');
export const ECOSYSTEM_PRODUCT_PREORDER_PRICE = getEnv('REACT_APP_ECOSYSTEM_PRODUCT_PREORDER_PRICE');
export const OPEN_REFERRAL_IN_APP = getEnv('REACT_APP_OPEN_REFERRAL_IN_APP') == 'true';