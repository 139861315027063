import React from 'react';
import { Layout } from 'antd';
import AuthenticatedMenuBar from '~/app/containers/Genesis/Menu/AuthenticatedMenuBar';
import Footer from '~/app/containers/Genesis/Footer/Footer';
import menuPropsCN from 'lib/menuPropsCN';
import footerPropsCN from 'lib/footerPropsCN';
import MenuBar from '~/app/containers/Genesis/Menu/MenuBar';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import PATHS from '~/app/utils/paths';

const { Content } = Layout;

const MENU_PROPS = window?.MENU_PROPS || menuPropsCN;
const FOOTER_PROPS = window?.FOOTER_PROPS || footerPropsCN;

export default function HeaderFooterLayout({ children, skipUserAuthentication = false }) {
  const c = useStyles();
  const location = useLocation();
  const { pathname } = location;

  const isPublicPage = pathname.includes(PATHS.RESERVE) || pathname.includes(PATHS.LOGIN);
  const isReferralLanding = pathname.includes(`/referral/`);

  const contentClassName = isReferralLanding ? "full" : ( isPublicPage ? c.clsResolver : "");

  return (
    <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {skipUserAuthentication ? <MenuBar {...MENU_PROPS} /> : <AuthenticatedMenuBar {...MENU_PROPS} />}
      <Content style={{ paddingTop: '28px' }} className={contentClassName}>
        {children}
      </Content>
      <Footer {...FOOTER_PROPS} />
    </Layout>
  );
}

const useStyles = createUseStyles({
  clsResolver: {
    minHeight: 'calc(100vh - 88px)'
  }
});
