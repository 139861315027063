import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';

function H1({ className, classes: c, children }) {
  return (
    <h1 className={cx(className, c.h1)}>{children}</h1>
  );
}

const styles = {
  h1: {
    fontSize: '40px',
    marginBottom: '36px',
    fontWeight: '300'
  }
};

export default injectSheet(styles)(H1);
