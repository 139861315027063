import { SSO_LOGIN_PROVIDER } from 'lib/constants';
import PATHS from './paths';

const REGIONS = {
  US: PATHS.US_BASE,
  CN: PATHS.CN_BASE
};

// Returns a path prefixed by a designated region, default US.
function getRegionPath(path, region) {
  if (region === REGIONS.CN) {
    return `${PATHS.CN_BASE}${path}`;
  }

  return `${PATHS.US_BASE}${path}`;
}

// Returns a path in the same region as the current url.
// Replace the dynamic params with provided values, if applicable
function getSameRegionPath(path, params) {
  // pathname gives 'us/path' from 'http://example.com/us/path'
  const { pathname } = window.location;
  const pathArray = pathname.split('/');
  let prefix;

  if (pathArray.length > 0) {
    prefix = `/${pathArray[0]}`;
  }
  if (!Object.values(REGIONS).includes(prefix)) {
    prefix = PATHS.CN_BASE;
  }

  let regionPath = `${prefix}${path}`;

  if (params) {
    Object.keys(params).forEach((k) => {
      regionPath = regionPath.replace(`:${k}`, params[k]);
    });
  }

  return regionPath;
}

function getSSOProviderByCallbackPath(path) {
  if (path.match(new RegExp(PATHS.SSO_CALLBACK_WECHAT, 'gi'))) {
    return SSO_LOGIN_PROVIDER.WECHAT;
  }

  if (path.match(new RegExp(PATHS.SSO_CALLBACK_SINAWEIBO, 'gi'))) {
    return SSO_LOGIN_PROVIDER.WEIBO;
  }

  if (path.match(new RegExp(PATHS.SSO_CALLBACK_APPLE, 'gi'))) {
    return SSO_LOGIN_PROVIDER.APPLE;
  }

  return undefined;
}

function getSSOProviderByPhoneBindingPath(path) {
  if (path.match(new RegExp(PATHS.SSO_PHONE_WECHAT, 'gi'))) {
    return SSO_LOGIN_PROVIDER.WECHAT;
  }

  if (path.match(new RegExp(PATHS.SSO_PHONE_SINAWEIBO, 'gi'))) {
    return SSO_LOGIN_PROVIDER.WEIBO;
  }

  return undefined;
}

function getSSOPhoneBindingPathByProvider(provider) {
  if (provider === SSO_LOGIN_PROVIDER.WECHAT) {
    return PATHS.SSO_PHONE_WECHAT;
  }

  if (provider === SSO_LOGIN_PROVIDER.WEIBO) {
    return PATHS.SSO_PHONE_SINAWEIBO;
  }

  if (provider === SSO_LOGIN_PROVIDER.APPLE) {
    return PATHS.SSO_PHONE_APPLE;
  }

  return undefined;
}

function isAppOnly() {
  if (typeof window === 'undefined') return false;

  const searchParams = new URLSearchParams(window.location.search);

  return searchParams.get('APPONLY') === '1';
}

export {
  getRegionPath,
  getSameRegionPath,
  REGIONS,
  getSSOProviderByCallbackPath,
  getSSOProviderByPhoneBindingPath,
  getSSOPhoneBindingPathByProvider,
  isAppOnly
};
