import React from 'react';
import injectSheet from 'react-jss';
import classNames from 'classnames';
import Icon from '@ff/ui/Icon';

class VideoPlayer extends React.Component {
  state = {
    isPlaying: false
  }

  handleVideoToggle = () => {
    if (this.state.isPlaying) {
      this.video.pause();
    } else {
      this.video.play();
    }

    this.setState(state => ({
      isPlaying: !state.isPlaying
    }));
  }

  render() {
    const { coverLink, videoUrl, classes: c, videoPlayPrefereces, playControl } = this.props;
    const videoControl = playControl && (
      <Icon
        name="ff-icons-play"
        className={classNames('ff-icons',
          this.state.isPlaying && c.hidePlayBtn)}
        onClick={this.handleVideoToggle}
      />
    );

    return (
      <div className={c.videoContainer}>
        <video
          autoPlay={videoPlayPrefereces.autoplay}
          muted={videoPlayPrefereces.muted}
          loop={videoPlayPrefereces.loop}
          className={c.videoElement}
          poster={coverLink}
          ref={(video) => {
            if (!this.video) {
              this.video = video;
            }
          }}
          onClick={this.handleVideoToggle}
        >
          <source src={videoUrl} type="video/mp4" />
        </video>
        {videoControl}
      </div>
    );
  }
}

const styles = {
  videoContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    '& > .ff-icons': {
      opacity: '1',
      color: 'white',
      fontSize: '20px',
      position: 'absolute',
      left: '50px',
      bottom: '50px',
      transition: 'all .2s ease-in-out'
    }
  },
  hidePlayBtn: {
    opacity: '0 !important',
    transform: 'scale(2)'
  },
  videoElement: {
    top: '0px',
    left: '-2px',
    width: '102%',
    height: '100%',
    opacity: '1',
    display: 'block',
    position: 'absolute',
    objectFit: 'cover',
    visibility: 'inherit',
    backgroundSize: 'cover'
  }
};

export default injectSheet(styles)(VideoPlayer);
