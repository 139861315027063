import React from 'react';
import injectSheet from 'react-jss';
import _ from 'lib/gettext';
import InputField from '~/app/components/FormFields/InputField';
import BodyText from '~/app/components/typography/BodyText';

function PasswordFields({ classes: c }) {
  return (
    <div className={c.container}>
      <BodyText className={c.marginBottom}>
        {_('MESSAGE_PROMPT_CREATE_PASSWORD')}
      </BodyText>
      <InputField
        name="password"
        validations={{
          matchRegexp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!-\/:-@\[-`{-~]).{8,}$/m // eslint-disable-line no-useless-escape
        }}
        validationErrors={{
          matchRegexp: _('MESSAGE_PASSWORD_RULES'),
          isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
        }}
        required
        fieldTitle={_('LABEL_PASSWORD')}
        className={c.inputField}
      />
      <InputField
        name="confirmPassword"
        validations="equalsField:password"
        validationErrors={{
          isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
        }}
        required
        validationError={_('ERROR_MATCH_PASSWORD')}
        fieldTitle={_('LABEL_CONFIRM_PASSWORD')}
      />
    </div>
  );
}

const styles = {
  container: {
    marginTop: '40px',
    marginBottom: '40px'
  },
  marginBottom: {
    marginBottom: '20px'
  },
  boxyRadio: {
    marginBottom: '15px'
  },
  fieldTitle: {
    fontWeight: '500',
    marginBottom: '10px'
  },
  inputField: {
    marginBottom: '40px'
  }
};

// TODO: Try verify user using the email.
export default injectSheet(styles)(PasswordFields);
