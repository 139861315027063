import React from 'react';
import injectSheet from 'react-jss';
import _ from 'lib/gettext';
import EmailField from './EmailField';
import QueryLoader from './QueryLoader';

class EmailFieldValidationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: ''
    };
    this.onBlur = this.onBlur.bind(this);
  }

  onBlur(input) {
    this.setState({
      input
    });
  }

  render() {
    const { classes: c, history, value = '' } = this.props;
    const { input } = this.state;
    const validationRules = [
      {
        trigger: 'onChange',
        validate: val => !!val,
        message: _('ERROR_REQUIRED_FIELD')
      }
    ];

    return (
      <div className={c.container}>
        <EmailField
          name="email"
          validations="isEmail"
          validationErrors={{
            isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD'),
            isEmail: _('ERROR_VALID_EMAIL')
          }}
          required
          fieldTitle={_('LABEL_EMAIL')}
          rules={validationRules}
          onBlur={this.onBlur}
          value={value}
        />
        <QueryLoader input={input} fieldTitle={_('LABEL_EMAIL')} history={history} />
      </div>
    );
  }
}


const styles = {
  container: {
    marginTop: '40px',
    marginBottom: '40px'
  },
  boxyRadio: {
    marginBottom: '15px'
  },
  fieldTitle: {
    fontWeight: '500',
    marginBottom: '10px'
  }
};

// TODO: Try verify user using the email.
export default injectSheet(styles)(EmailFieldValidationContainer);
