export const CURRENCY_TO_SYMBOL = {
  USD: '$',
  RMB: '￥',
  CNY: '元'
};

export const PAYMENT_CHANNEL = {
  ALIPAY: 'ALIPAY',
  MALIPAY: 'MALIPAY', // M for mobile
  WECHAT: 'WECHAT',
  MWECHAT: 'MWECHAT'
};

export const ENV_DOMAINS = {
  LOC: 'localhost',
  DEV_CN: 'https://dev-web-cn.ffau.to/cn/',
  DEV_US: 'https://dev-web.ffau.to/us/',
  QA_CN: 'https://qa-web-cn.ffau.to/cn/',
  QA_US: 'https://qa-web.ffau.to/us/',
  STG_CN: 'https://staging-web-cn.ffau.to/cn/',
  STG_US: 'https://staging-web.ffau.to/us/',
  PRD_CN: 'https://www.faradayfuturecn.com/cn/',
  PRD_US: 'https://www.ff.com/us/'
};

export const APP_RESERVE_STATE = Object.freeze({
  APP_USER_LOGIN: 0,
  SELECT_RESERVE_TYPE: 1,
  FILL_USER_INFO: 2,
  FILL_BILL_INFO: 3,
  RESERVE_AND_PAY: 4
});

export const APP_RESERVE_TYPE = Object.freeze({
  FF91_FUTURIST_ALLIANCE: '91FA',
  FF91_FUTURIST: '91FS',
  FF91: 'FF91',
  ECOPRODUCT: 'ECOPRODUCT'
});

export const APP_MODEL_RESERVE_TYPE_MAPPING = Object.freeze({
  'FF91FNA-ALLIANCE': APP_RESERVE_TYPE.FF91_FUTURIST_ALLIANCE,
  'FF91FNA': APP_RESERVE_TYPE.FF91_FUTURIST,
  'FF91': APP_RESERVE_TYPE.FF91,
});

export const REFUND_REASON_CODES = Object.freeze([
  'REFUND_REASON_NOT_WANT_TO_PURCHASE',
  'REFUND_REASON_DIFFICULT_TO_PURCHASE',
  'REFUND_REASON_INFORMATION_NOT_CLEAR',
  'REFUND_REASON_TOO_LONG_TO_DELIVER',
  'REFUND_REASON_LACK_OF_CONFIDENCE',
  'REFUND_REASON_LOW_CONSULTATION_EFFICIENCY',
  'REFUND_REASON_OTHER'
]);

export const ECO_REFUND_REASON_CODES = Object.freeze([
  'ECO_REFUND_REASON_BAD_DESCRIPTION',
  'ECO_REFUND_REASON_HIGH_PRICE',
  'ECO_REFUND_REASON_WAIT_TOO_LONG',
  'ECO_REFUND_REASON_PERSONAL_REASON',
  'ECO_REFUND_REASON_NO_VEHICLE',
  'ECO_REFUND_REASON_OTHER'
]);

export const SSO_LOGIN_PROVIDER = Object.freeze({
  WECHAT: 'WECHAT',
  WEIBO: 'WEIBO',
  APPLE: 'APPLE'
});

// If the feature flag is enabled, use 2.0 text
export const FF91_TEXT = (typeof window !== 'undefined' && window?._env_?.REACT_APP_ENABLE_2_0_TEXT === 'true')
  ? 'FF 91 2.0'
  : 'FF 91'