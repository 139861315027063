import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHS from '~/app/utils/paths';
import PrivateRoute from '~/app/containers/PrivateRoute';
import HeaderFooterLayout from '~/app/layouts/HeaderFooterLayout';
import loadable from '@loadable/component';

const ReserveSuccess = loadable(() => import(/* webpackChunkName: "ReserveSuccess" */ '~/app/containers/Reserve/Success'));
const ReservationDetails = loadable(() => import(/* webpackChunkName: "ReservationDetails" */ '~/app/containers/Reserve/Details'));
const PaymentPage = loadable(() => import(/* webpackChunkName: "PaymentPage" */ '~/app/containers/PaymentPage'));
export const ReserveCN = loadable(() => import(/* webpackChunkName: "ReserveCN" */ '~/app/containers/Reserve/cn/ReserveCN'));
const PaymentAmountLimitation = loadable(() => import(/* webpackChunkName: "PaymentAmountLimitation" */ '~/app/containers/PaymentAmountLimitation'));
export const ReferralLanding = loadable(() => import(/* webpackChunkName: "ReserveCN" */ '~/app/containers/Referrals/ReferralLanding'));
const EcoProductPreorderPage = loadable(() => import(/* webpackChunkName: "EcoProductPreorderPage" */ '~/app/containers/EcoProductPreorderPage'));

import { ENABLE_NEW_REFERRAL, ENABLE_NEW_ECOSYSTEM_PRODUCT } from '~/app/settings';


const ReserveRouter = () => {
  return (
    <HeaderFooterLayout>
      <Switch>
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.RESERVE_SUCCESS}`} component={ReserveSuccess} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.RESERVE_DETAILS}`} component={ReservationDetails} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MAKE_PAYMENT}`} component={PaymentPage} />
        <Route exact path={`/:locale(us|cn)${PATHS.RESERVE}`} component={ReserveCN} />
        {ENABLE_NEW_REFERRAL && (<Route exact path={`/:locale(us|cn)${PATHS.REFERRAL}`} component={ReferralLanding} />)}
        {ENABLE_NEW_ECOSYSTEM_PRODUCT && (
          <Route exact path={`/:locale(us|cn)${PATHS.ECOSYSTEM_PRODUCT_PREORDER}`} component={EcoProductPreorderPage} />
        )}
        <Route exact path={`/:locale(us|cn)${PATHS.PAYMENT_LIMITATION}`} component={PaymentAmountLimitation} />
        <Redirect to="/:locale(us|cn)/404" />
      </Switch>
    </HeaderFooterLayout>
  );
};

export default ReserveRouter;
