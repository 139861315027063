/* eslint-disable */
import errorCode from './localizedErrorCodes';
import { FF91_TEXT } from './constants';

export default {
  ...errorCode,
  LABEL_TITLE_DEFAULT: {
    en: 'Experience a New Species | Faraday Future',
    zh: 'Experience a New Species | Faraday Future',
  },
  LABEL_LOGOUT: {
    en: 'Log Out',
    zh: '退出',
  },
  LABEL_LOGIN: {
    en: 'Log In',
    zh: '登录',
  },
  LABEL_ERROR: {
    en: 'Error',
    zh: '错误',
  },
  LABEL_REGISTER: {
    en: 'Register',
    zh: '注册',
  },
  LABEL_SSO_LOGIN: {
    en: 'Other Login',
    zh: '其他登录方式',
  },
  LABEL_BINDING_PHONE: {
    en: 'Please bind phone number',
    zh: '请绑定手机号',
  },
  MESSAGE_BINDING_PHONE_COMMON: {
    en: 'Login directly after phone binding',
    zh: '绑定后可以直接登录',
  },
  MESSAGE_BINDING_PHONE_WECHAT: {
    en: 'Login via WeChat directly after phone binding',
    zh: '绑定后可以直接用微信登录',
  },
  MESSAGE_BINDING_PHONE_SINAWEIBO: {
    en: 'Login via Weibo directly after phone binding',
    zh: '绑定后可以直接用微博登录',
  },
  MESSAGE_LOGIN_IN_US: {
    en: 'You are logging in to the US site. Not from the United States? ',
    zh: '您正在登录美国站。拥有中国账户？',
  },
  MESSAGE_LOGIN_IN_CN: {
    en: 'You are logging in to the China site. Not from China? ',
    zh: '您正在登录中国区网站 不是中国账户？',
  },
  LABEL_CLICK_HERE: {
    en: 'Click Here',
    zh: '点击这里',
  },
  MESSAGE_SIGN_UP: {
    en: 'Creating an account is the first step on the journey to becoming one of the first to own our flagship vehicle. Register to be contacted about company news and invitations to exclusive events. If you have already created an account, log in to update and view your account information.',
    zh: '建立账户作为成为车主的第一步，注册后将被邀请接收我司的相关新闻和活动信息，如您已有账户，可直接登录查阅个人预订信息。',
  },
  LABEL_EMAIL: {
    en: 'Email',
    zh: '邮箱',
  },
  ERROR_VALID_EMAIL: {
    en: 'The input is not a valid E-mail!',
    zh: '邮箱格式不正确',
  },
  ERROR_EMPTY_EMAIL: {
    en: 'Please enter your E-mail!',
    zh: '请输入您的邮箱地址！',
  },
  ASSOCIATED_MOBILE_NUMBER: {
    en: 'An account is associated with this mobile number.',
    zh: '此号码已经注册。',
  },
  ASSOCIATED_MOBILE_NUMBER_CONTINUED: {
    en: 'to continue.',
    zh: '继续',
  },
  LABEL_PASSWORD: {
    en: 'Password',
    zh: '密码',
  },
  ERROR_EMPTY_PASSWORD: {
    en: 'Please input your Password',
    zh: '此项为必填项',
  },
  LABEL_FORGOT_PASSWORD: {
    en: ' FORGOT PASSWORD',
    zh: '忘记密码',
  },
  VERIFICATION_CODE: {
    en: 'Verification Code',
    zh: '短信验证码',
  },
  ERROR_VERIFICATION_CODE_FORMAT: {
    en: 'Invalid verification code, please apply a new verification code',
    zh: '无效的验证码，请申请一个新的验证码',
  },
  SEND_VERIFICATION_CODE: {
    en: 'Send Verification Code',
    zh: '发送验证码',
  },
  RESEND_VERIFICATION_CODE: {
    en: 'Resend',
    zh: '再次发送',
  },
  LABEL_EDIT_ACCOUNT: {
    en: 'EDIT ACCOUNT',
    zh: '编辑账户',
  },
  LABEL_CHANGE_PHONE: {
    en: 'CHANGE PHONE NUMBER',
    zh: '更换手机号码',
  },
  LABEL_CHANGE_PASSWORD: {
    en: 'CHANGE PASSWORD',
    zh: '更改密码',
  },
  LABEL_DELETE_ACCOUNT: {
    en: 'DELETE ACCOUNT',
    zh: '删除帐户',
  },
  BUTTON_VIEW: {
    en: 'VIEW',
    zh: '查看',
  },
  LABEL_GREETING: {
    en: '',
    zh: '您好',
  },
  LABEL_SUBMIT: {
    en: 'SUBMIT',
    zh: '提交',
  },
  LABEL_SUBMIT_PAY: {
    en: 'SUBMIT AND PAY',
    zh: '提交并支付',
  },
  LABEL_NEED_TO_PAY: {
    en: 'Need to pay:',
    zh: '待支付：',
  },
  LABEL_CANCEL: {
    en: 'CANCEL',
    zh: '取消',
  },
  LABEL_CONFIRM: {
    en: 'CONFIRM',
    zh: '确认',
  },
  LABEL_SAVE: {
    en: 'SAVE',
    zh: '保存',
  },
  LABEL_SAVING: {
    en: 'SAVING',
    zh: '保存中',
  },
  LABEL_ARE_YOU_SURE: {
    en: 'Are you sure?',
    zh: '确认注销帐号吗?',
  },
  LABEL_ARE_YOU_SURE_DESCRIPTION_1: {
    en: 'Delete will delete all your information and lose your reservation.',
    zh: '注销帐号后将不可恢复.',
  },
  LABEL_ARE_YOU_SURE_DESCRIPTION_2: {
    en: 'Once you are done, you will not be able to undo this.',
    zh: '一旦完成，您将无法撤消此操作。',
  },
  LABEL_APPLY_CANCELATION: {
    en: 'Apply for cancelation',
    zh: '申请注销帐号',
  },
  LABEL_APPLY_CANCELATION_DESCRIPTION_1: {
    en: 'Deleting will erase all your information and will loose your reservation. You cannot undo this once done.',
    zh: '为了确保你的账号, 车辆及权限安全, FF将在你注销账号前进行以下验证：',
  },
  LABEL_APPLY_CANCELATION_TERMS_1: {
    en: "Process in 15 days, do not change your password or infoto confirm your account isn't stolen.",
    zh: '账号处于安全状态: 过去15日内无修改密码, 修改绑定信息等敏感操作；账号无任何纠纷, 包括投诉, 举报或被投诉, 被举报；账号处于有效状态, 且没有被封号等账户限制状态。',
  },
  LABEL_APPLY_CANCELATION_TERMS_2: {
    en: 'This account will no longer be associated with your FF91 car.',
    zh: '账号下无任何关联车辆: 账号不是任何一辆车的管理权所有者；账号不是任何一辆车的授权用车人。',
  },
  LABEL_APPLY_CANCELATION_TERMS_3: {
    en: 'Transactions associated with this account, all orders belonging to this account will end.',
    zh: '账号下无正在进行中的订单和交易: 账号下所有订单均已完成（包括取消和退款完成), 包括不限于车辆订单, 服务订单, 商品订单。',
  },
  LABEL_APPLY_CANCELATION_TERMS_4: {
    en: "After the account will be deactivated you cannot use this account including your avatar, every login method will be removed. We'll remove all real personal information and all personal information and history will not be stored.",
    zh: '账号注销后, 您将无法再使用该账号, 包括但不限于: 无法登录, 使用FF 网站账号；移除该账号下的所有登录方式；移除该账号下的实名认证信息；该账号下的个人资料和历史信息都将无法找回。',
  },
  LABEL_WHY_LEAVE: {
    en: "Tell us why you're leaving?",
    zh: '选择注销原因',
  },
  LABEL_WHY_LEAVE_DESCRIPTION_1: {
    en: 'Please let us know why you decided to delete your account',
    zh: '请告诉我们您决定删除帐户的原因',
  },
  LABEL_WHY_LEAVE_REASON_1: {
    en: 'I have more than one account',
    zh: '我有多个帐户',
  },
  LABEL_WHY_LEAVE_REASON_2: {
    en: 'Not interested in FF anymore',
    zh: '对FF不再感兴趣',
  },
  LABEL_WHY_LEAVE_REASON_3: {
    en: 'Other reasons: Why do you want to deactivate your account',
    zh: '其他原因：您为什么要停用您的帐户',
  },
  LABEL_CONFIRM_DESCRIPTION_1: {
    en: 'Are you sure you want to deactivate your account?',
    zh: '帐号注销后您无法再使用该帐号',
  },
  LABEL_CONFIRM_DESCRIPTION_2: {
    en: 'Once you deactivate you cannot recover it',
    zh: '并且您帐号的所有讯息将不可恢复',
  },
  LABEL_DELETE_SUCCESS: {
    en: 'Delete Success',
    zh: '注销成功!',
  },
  LABEL_SUCCESS_DESCRIPTION_1: {
    en: 'Your submission has been processed.',
    zh: '你的帐号已经被注销系统将自动退出该帐号。',
  },
  LABEL_SUCCESS_DESCRIPTION_3: {
    en: 'For assistance call 400-700-9191',
    zh: '如需帮忙请联系客服: 请致电 400-700-9191',
  },
  MESSAGE_REGISTER_WARNING: {
    en: 'Your first and last name cannot be changed after account creation. For questions or assistance, please call us at (800) 228-7702.',
    zh: '账户创建后姓名不能更改。如有问题或需要协助，请拨打(800) 228-7702联系我们。',
  },
  LABEL_FIRST_NAME: {
    en: 'First Name',
    zh: '名字',
  },
  LABEL_NAME: {
    en: 'Name',
    zh: '姓名',
  },
  LABEL_LAST_NAME: {
    en: 'Last Name',
    zh: '姓氏',
  },
  LABEL_YOUR_NAME: {
    en: 'Your Name',
    zh: '您的姓名',
  },
  LABEL_YOUR_GENDER: {
    en: 'Your Gender',
    zh: '您的性别',
  },
  LABEL_YOUR_GENDER_ERROR_MESSAGE: {
    en: '',
    zh: '请选择您的性别',
  },
  LABEL_MALE: {
    en: 'Male',
    zh: '男',
  },
  LABEL_FEMALE: {
    en: 'Female',
    zh: '女',
  },
  LABEL_YOUR_AGE: {
    en: 'Your AGE',
    zh: '您的年龄',
  },
  LABEL_YOUR_AGE_ERROR_MESSAGE: {
    en: '',
    zh: '请选择您的年龄',
  },
  LABEL_AGE_BELOW_20: {
    en: 'Below 20',
    zh: '20岁以下',
  },
  LABEL_AGE_20_TO_30: {
    en: '20 - 30',
    zh: '20 - 30岁',
  },
  LABEL_AGE_30_TO_50: {
    en: '30 - 50',
    zh: '30 - 50岁',
  },
  LABEL_AGE_50_ABOVE: {
    en: '50 Above',
    zh: '50岁以上',
  },
  LABEL_YOUR_EDUCATION: {
    en: 'Your Education',
    zh: '您的学历',
  },
  LABEL_YOUR_EDUCATION_ERROR_MESSAGE: {
    en: '',
    zh: '请选择您的学历',
  },
  LABEL_EDUCATION_MASTER_ABOVE: {
    en: 'Master Above',
    zh: '研究生及以上',
  },
  LABEL_EDUCATION_BACHELOR: {
    en: 'Bachelor',
    zh: '本科生',
  },
  LABEL_EDUCATION_BELOW_COLLEGE: {
    en: 'Below College',
    zh: '大专生及以下',
  },
  LABEL_YES: {
    en: 'Yes',
    zh: '是',
  },
  LABEL_NO: {
    en: 'No',
    zh: '否',
  },
  LABEL_ZIP_US_ONLY: {
    en: 'ZIP CODE (US ONLY)',
    zh: '邮政编码（仅限美国）',
  },
  LABEL_ZIP: {
    en: 'Zip Code',
    zh: '邮政编码',
  },
  LABEL_MOBILE: {
    en: 'Mobile',
    zh: '手机号码',
  },
  LABEL_MOBILE_SHORT: {
    en: 'Mobile',
    zh: '手机',
  },
  LABEL_PHONE_NUMBER: {
    en: 'Phone Number',
    zh: '手机号码',
  },
  LABEL_NEW_PHONE_NUMBER: {
    en: 'New Phone Number',
    zh: '新手机号码',
  },
  LABEL_FUTURIST_ALLIANCE_INVITATION_CODE: {
    en: 'Futurist Alliance Invitation Code',
    zh: '未来主义者联盟邀请码',
  },
  LABEL_SUBSCRIPTION: {
    en: 'NEWSLETTER SUBSCRIPTION',
    zh: '订阅简报',
  },
  LABEL_SUBSCRIBED: {
    en: 'Subscribed',
    zh: '已订阅',
  },
  LABEL_NOT_SUBCRIBED: {
    en: 'Not Subscribed',
    zh: '未订阅',
  },
  LABEL_SUBSCRIBE_PROMPT: {
    en: 'Subscribe to the FF newsletter',
    zh: '订阅FF简报',
  },
  LABEL_LOADING: {
    en: 'Loading',
    zh: '正在加载',
  },
  LABEL_MY_ACCOUNT: {
    en: 'My Account',
    zh: '我的账户',
  },
  MESSAGE_MY_ACCOUNT: {
    en: 'Your account profile can be updated here.',
    zh: '您可以查看更新您的个人信息',
  },
  LABEL_RESERVE_FF_91: {
    en: `Reserve an ${FF91_TEXT}`,
    zh: `预订${FF91_TEXT}`,
  },
  LABEL_RESERVE: {
    en: 'Reserve',
    zh: '预订',
  },
  MESSAGE_RESERVE_BEGIN: {
    en: `Reservations are now open. Click the button to begin the ${FF91_TEXT} reservation process.`,
    zh: `预订现已开启。请点击按钮以开始${FF91_TEXT}预订流程。`,
  },
  HEADER_EXISTING_RESERVATIONS: {
    en: 'Existing Reservations',
    zh: '我的订单',
  },
  HEADER_MY_REWARD_POINTS: {
    en: 'My Points',
    zh: '未来主义者共创积分',
  },
  LABEL_EXISTING_RESERVATIONS: {
    en: 'Existing Reservations',
    zh: '已有订单',
  },
  MESSAGE_EXISTING_RESERVATIONS: {
    en: 'If you have already made a reservation, you can check your orders here.',
    zh: '如果已有预订，您可以查看订单',
  },
  LABEL_HERE: {
    en: 'here',
    zh: '这里',
  },
  LABEL_UNKNOWN: {
    en: 'Unknown',
    zh: '未知',
  },
  LABEL_OLD_PASSWORD: {
    en: 'Old Password',
    zh: '旧密码',
  },
  LABEL_NEW_PASSWORD: {
    en: 'New Password',
    zh: '新密码',
  },
  MESSAGE_ENTER_NEW_PHONE: {
    en: 'Please enter your new phone number',
    zh: '请输入新手机号码',
  },
  MESSAGE_ENTER_OLD_PASSWORD: {
    en: 'Please enter your old password!',
    zh: '请输入旧密码',
  },
  MESSAGE_CONFIRM_PASSWORD: {
    en: 'Please confirm your password!',
    zh: '请确认密码',
  },
  LABEL_CONFIRM_PASSWORD: {
    en: 'Confirm Password',
    zh: '确认密码',
  },
  MESSAGE_PASSWORD_RULES: {
    en: 'Must be at least 8 characters and include at least one number, uppercase and lower case letter, and one non-alphanumeric ASCII symbol.',
    zh: '要求至少8个字符，至少包括1个数字、1个大写字母，1个小写字母和1个特殊字符!@#$%^&+=-',
  },
  MESSAGE_PASSWORD_RULES_ONE_NUMBER: {
    en: 'Must contain at least 1 number',
    zh: '至少要有一个数字',
  },
  MESSAGE_PASSWORD_RULES_EIGHTH_CHARACTERS_LONG: {
    en: 'Password must be at least 8 characters long',
    zh: '至少要有8个字符',
  },
  MESSAGE_PASSWORD_RULES_ONE_UPPERCASE_LETTERS: {
    en: 'Must contain 1 or more uppercase letters',
    zh: '至少要有一个大写字母',
  },
  MESSAGE_PASSWORD_RULES_ONE_LOWERCASE_LETTERS: {
    en: 'Must contain 1 or more lowercase letters',
    zh: '至少要有一个小写字母',
  },
  MESSAGE_PASSWORD_RULES_ASCII_SYMBOLS: {
    en: 'Must contain 1 or more special characters: !@#$%^&+=-',
    zh: '至少要有1个特殊字符!@#$%^&+=-',
  },
  MESSAGE_PASSWORD_RULES_INVALID_CHARACTERS: {
    en: 'Your password contains invalid characters. Please only use the following special characters: !@#$%^&+=-',
    zh: '您的密码中包含了无效字符，请仅使用以下特殊字符!@#$%^&+=-',
  },
  MESSAGE_PASSWORD_CHANGED: {
    en: 'Your password has been changed.',
    zh: '您的密码已经更改',
  },
  MESSAGE_PASSWORD_CHANGED_SUCCESSFULLY: {
    en: 'You have changed password successfully',
    zh: '修改密码成功',
  },
  MESSAGE_PHONE_RESET_SUCCESSFULLY: {
    en: 'You have reset phone successfully',
    zh: '更换手机号码成功!',
  },
  MESSAGE_VERIFY_PHONE: {
    en: 'To secure your account, please verify your current phone number',
    zh: '为保证你的账户安全，更换手机前需要先验证当前手机号。',
  },
  MESSAGE_LOSE_CURRENT_PHONE: {
    en: 'If your current phone number expired or is no longer used, please contact customer service',
    zh: '若当前号码已不用或丢失，请联系客服',
  },
  LABEL_RETURN_TO_ACCOUNT: {
    en: 'RETURN TO ACCOUNT',
    zh: '回到账户',
  },
  LABEL_CLICK_TO_SUBSCRIBE: {
    en: 'Subscribe to Faraday Future News and Updates',
    zh: '点击以订阅FF简报',
  },
  MESSAGE_HAVE_READ: {
    en: 'I have read and understood the Faraday Future',
    zh: '我已阅读并同意',
  },
  MESSAGE_HAVE_READ_DELETE_ACCOUNT: {
    en: 'FF account delete important reminder',
    zh: 'FF账号注销重要提醒',
  },
  LABEL_JOIN_WAITLIST: {
    en: `I would like to join the waitlist for the ${FF91_TEXT} Futurist Alliance Edition`,
    zh: `我想加入${FF91_TEXT} 未来主义者联盟版的候补名单`,
  },
  LABEL_PRIVACY_POLICY: {
    en: 'Privacy Policy',
    zh: '隐私政策',
  },
  LABEL_REGISTER_AGREEMENT: {
    en: 'Account Registration Agreement',
    zh: '网站用户注册协议 & 使用条款',
  },
  LABEL_TERM_OF_USE: {
    en: 'Terms of Use',
    zh: '使用条款',
  },
  LABEL_STANDARD_RESERVE_DESCRIPTION_1: {
    en: `A Standard Reservation expresses your interest in reserving an ${FF91_TEXT} but does not hold a spot in line.`,
    zh: '此预约完全免费，您也可以在未来任何时候重新付费预订',
  },
  MESSAGE_REGISTER_RESERVATION: {
    en: `I'm making a commitment-free reservation of ${FF91_TEXT}.`,
    zh: `免费预约获取${FF91_TEXT}最新产品及活动信息`,
  },
  MESSAGE_AND_THE: {
    en: 'and the',
    zh: '和',
  },
  ERROR_MUST_AGREE_TO_REGISTER: {
    en: 'You must agree to register for an account.',
    zh: '必须同意以注册账户。',
  },
  ERROR_EMPTY_FIRST_NAME: {
    en: 'Please enter your first name!',
    zh: '请输入您的名字',
  },
  ERROR_EMPTY_NAME: {
    en: 'Please enter your name',
    zh: '请输入您的姓名',
  },
  ERROR_EMPTY_LAST_NAME: {
    en: 'Please enter your last name!',
    zh: '请输入您的姓氏',
  },
  ERROR_EMPTY_PHONE_NUMBER: {
    en: 'Please enter your phone number!',
    zh: '请输入您的手机号码',
  },
  MESSAGE_WELCOME: {
    en: 'Welcome,',
    zh: '欢迎',
  },
  MESSAGE_SUCCESS_LOGIN: {
    en: "You have successfully logged in. If you're not automatically redirected to your FF account page, please click below.",
    zh: '您已登陆成功，如页面未跳转到FF账户页面，请点击下面链接。',
  },
  MESSAGE_SUCCESS_UPDATE_PROFILE: {
    en: 'Profile updated successfully',
    zh: '修改资料成功',
  },
  HEADER_MY_FF_POINTS: {
    en: 'My FF Points',
    zh: '未来主义者共创积分',
  },
  LABEL_MY_FF_POINTS: {
    en: 'FF Points',
    zh: 'FF点数',
  },
  LABEL_NUMBER: {
    en: 'Number',
    zh: '点数',
  },
  LABEL_TIME_FF_POINTS: {
    en: 'Time',
    zh: '时间',
  },
  MESSAGE_MY_FF_POINTS: {
    en: 'If you have gained FF points, you can view the details here.',
    zh: '',
  },
  WHAT_IS_FF_POINTS: {
    en: 'What is FF Points?',
    zh: '什么是FF点数',
  },
  MY_FF_POINTS_ACTIVITIES: {
    en: 'My FF Points Activities',
    zh: '未来主义者共创积分活动',
  },
  RESERVATION: {
    en: 'Reservation',
    zh: '预订',
  },
  LOYALTY: {
    en: 'Loyalty',
    zh: '忠诚',
  },
  SUBHEADER_MY_REFERRALS: {
    en: 'Start inviting your friends today!',
    zh: `现在就开始向您的朋友推荐${FF91_TEXT}`,
  },
  HEADER_MY_REFERRALS: {
    en: 'My Referrals',
    zh: 'FF 引荐计划',
  },
  REFERRAL_CODE: {
    en: 'Referral Code',
    zh: '引荐码'
  },
  LABEL_REFERRALS: {
    en: 'Referrals',
    zh: 'FF 引荐计划',
  },
  REFERRAL_SUCCESS_MESSAGE_1: {
    en: 'Find your own referral link in ',
    zh: '去',
  },
  REFERRAL_SUCCESS_MESSAGE_2: {
    en: 'My Referrals',
    zh: 'FF 引荐计划',
  },
  REFERRAL_SUCCESS_MESSAGE_3: {
    en: ' and share with your friends today.',
    zh: `里查看我的专属链接，即刻邀请朋友预订${FF91_TEXT}`,
  },
  REFERRAL_MYFF_SUMMARY_1: {
    en: '',
    zh: '您已累计获得',
  },
  REFERRAL_MYFF_SUMMARY_2: {
    en: '',
    zh: '购车现金券',
  },
  REFERRALS_INTRO_TEXT: {
    en: '',
    zh: '恭喜您获得新用户预订推荐权，通过分享以下链接，您将有机会获得购车现金券等的多项权益。',
  },
  MY_REFERRAL_LINK: {
    en: '',
    zh: '我的专属链接',
  },
  REFERRAL_STEPS_HEADER: {
    en: '',
    zh: '快速了解推荐制',
  },
  REFERRAL_STEP1_HEADER: {
    en: '',
    zh: '第一步',
  },
  REFERRAL_STEP1_DESCRIPTION: {
    en: '',
    zh: '将链接分享给自己的朋友',
  },
  REFERRAL_STEP2_HEADER: {
    en: '',
    zh: '第二步',
  },
  REFERRAL_STEP2_DESCRIPTION: {
    en: '',
    zh: '朋友成功预订',
  },
  REFERRAL_STEP3_HEADER: {
    en: '',
    zh: '第三步',
  },
  REFERRAL_STEP3_DESCRIPTION: {
    en: 'You and your friend each get $1000 cash coupon for each priority reservation.',
    zh: '您和朋友各得10000元人民币现金券用于购车',
  },
  REFERRAL_STEP4_HEADER: {
    en: '',
    zh: '第四步',
  },
  REFERRAL_STEP4_DESCRIPTION: {
    en: 'Apply the cash coupon upon completing your order.',
    zh: '购车订单交付时使用现金券',
  },
  REFERRAL_LIST_SUBHEADER: {
    en: '',
    zh: '我的推荐记录',
  },
  REFERRAL_TITLE_PRIORITY_50000: {
    en: '',
    zh: `${FF91_TEXT}梦想合伙人版预订-50000元订金`,
  },
  REFERRAL_TITLE_PRIORITY_10000: {
    en: '',
    zh: `${FF91_TEXT}梦想合伙人版预订-10000元订金`,
  },
  REFERRAL_TITLE_STANDARD: {
    en: '',
    zh: `${FF91_TEXT}预约`,
  },
  REFERRAL_I_REFERRED: {
    en: '',
    zh: '我已推荐',
  },
  REFERRAL_I_WAS_REFERRED: {
    en: '',
    zh: '我被推荐',
  },
  REFERRAL_CANCELLED: {
    en: '',
    zh: '已取消',
  },
  REFERRAL_REWARDS: {
    en: '',
    zh: '奖励',
  },
  REFERRAL_NO_REWARDS: {
    en: '',
    zh: '您暂时还未获得任何权益',
  },
  REFERRAL_TOTAL_DEDUCTION: {
    en: 'Total cash coupon: ',
    zh: '推荐总现金券：',
  },
  REFERRAL_DEDUCTION_INFO: {
    en: '',
    zh: '您可在支付尾款时使用此现金券',
  },
  REFERRAL_CASH_DEDUCTION: {
    en: ' cash coupon',
    zh: '现金券',
  },
  MESSAGE_REFERRALS: {
    en: 'If you have shared your link, you can view all your successful referral records here.',
    zh: '如果您已经分享过下方的专属预订推荐链接，您可以在此处查询所有的成功推荐记录。',
  },
  MESSAGE_NEW_REFERRALS: {
    en: 'If you have shared your link, you can view all your successful referral records here.',
    zh: '引荐好友购车 赚取共创积分',
  },
  LABEL_PREORDER: {
    en: 'Preorder',
    zh: '预订单',
  },
  LABEL_SHARE: {
    en: 'Share',
    zh: '分享至',
  },
  LABEL_SHARE_REFERRAL_1: {
    en: `Discover the endless possibilities of ${FF91_TEXT}`,
    zh: `探索关于${FF91_TEXT}的无限可能性`,
  },
  LABEL_SHARE_REFERRAL_2: {
    en: 'Use my link to get extra referral benefits when you reserve one',
    zh: '通过此链接下单，解锁新的预订和推荐权益',
  },
  LABEL_SHARE_REFERRAL_SEPARATOR: {
    en: '. ',
    zh: '. ',
  },
  REWARDS_MYFF_SUMMARY_1: {
    en: 'You have earned ',
    zh: '您已累计获得',
  },
  REWARDS_MYFF_SUMMARY_2: {
    en: ' FF Coins as of today',
    zh: 'FF币',
  },
  REWARDS_FF_COINS: {
    en: '',
    zh: 'FF币',
  },
  LABEL_MY_RESERVATIONS: {
    en: 'My Reservations',
    zh: '我的预订',
  },
  LABEL_MY_DESIGNS: {
    en: 'My Designs',
    zh: '我的设计',
  },
  LABEL_DESIGNS: {
    en: 'Designs',
    zh: '我的设计',
  },
  MESSAGE_EXISTING_DESIGNS: {
    en: `View your saved designs from our ${FF91_TEXT} Configurator.`,
    zh: '查看您已保存的配置信息',
  },
  LABEL_BACK_TO_ACCOUNT: {
    en: 'BACK TO MY ACCOUNT',
    zh: '返回我的账户',
  },
  LABEL_COPY: {
    en: 'Copy',
    zh: '复制',
  },
  LABEL_COPIED: {
    en: 'Copied!',
    zh: '已复制',
  },
  MESSAGE_UPGRADE: {
    en: 'If you want to upgrade to a Priority Reservation, please call us at (800) 228-7702 or email us at',
    zh: '如果您想升级至优先预订，请拨打(800) 228-7702或发邮件至',
  },
  MESSAGE_UPGRADE_BOOKING_SUCCESSFULLY: {
    en: 'If your want to change to “Priority reservation”, please click',
    zh: `如果您希望获得${FF91_TEXT} “梦想合伙人“版车型的预订资格，请点击`,
  },
  MESSAGE_NO_DESIGNS_1: {
    en: 'You have no designs yet. ',
    zh: '您还没有保存任何配置. ',
  },
  MESSAGE_NO_DESIGNS_2: {
    en: `Configure your ${FF91_TEXT} now.`,
    zh: '开始您的专属配置.',
  },
  MESSAGE_NO_ORDERS: {
    en: 'You have no existing orders. If you think this is in error, please call us at (800) 228-7702 or email us at',
    zh: '',
  },
  MESSAGE_UNPAID_ORDER_WAS_CANCELLED: {
    en: 'Your un-paid order has been cancelled, you can re-order it now.',
    zh: '您的预定单在保留期内未支付，已经被取消。如果需要您可以重新下单预定。',
  },
  MESSAGE_UNPAID_ORDER_WAS_CANCELLED_PART1: {
    en: '',
    zh: '您的预定单在保留期内未支付，已经被取消。如果需要',
  },
  MESSAGE_UNPAID_ORDER_WAS_CANCELLED_PART2: {
    en: '',
    zh: '您可以重新下单预定',
  },
  ERROR_CARD_EXPIRED: {
    en: 'Card expired.',
    zh: '卡已失效',
  },
  LABEL_CARD_NUMBER: {
    en: 'Card Number',
    zh: '卡号',
  },
  ERROR_EMPTY_EXP_MONTH: {
    en: 'Select expiration month.',
    zh: '选择失效月份',
  },
  LABEL_EXP_MONTH: {
    en: 'Exp. Month',
    zh: '失效月份',
  },
  ERROR_EMPTY_EXP_YEAR: {
    en: 'Select year.',
    zh: '选择年份',
  },
  LABEL_EXP_YEAR: {
    en: 'Exp. Year',
    zh: '失效年份',
  },
  ERROR_EMPTY_BILLING: {
    en: 'Please enter your billing address.',
    zh: '请输入您的账单地址',
  },
  LABEL_BILLING_LINE_1: {
    en: 'Billing Address 1',
    zh: '账单地址第1行',
  },
  LABEL_BILLING_LINE_2: {
    en: 'Billing Address 2',
    zh: '账单地址第2行',
  },
  LABEL_REGION: {
    en: 'Region',
    zh: '地区',
  },
  ERROR_EMPTY_REGION: {
    en: 'Please select region',
    zh: '请选择省份',
  },
  ERROR_EMPTY_CITY: {
    en: 'Please enter your city.',
    zh: '请输入您的城市',
  },
  LABEL_CITY: {
    en: 'City',
    zh: '市/区',
  },
  SELECT_LABEL_CITY: {
    en: 'Select City',
    zh: '选择城市',
  },
  SELECT_LABEL_PROVINCE_FIRST: {
    en: 'Select province first',
    zh: '先选择省份',
  },
  LABEL_PROVINCE: {
    en: 'Province',
    zh: '省/直辖市',
  },
  SELECT_LABEL_PROVINCE: {
    en: 'Select province*',
    zh: '选择省份*',
  },
  PLEASE_SELECT_A_ITEM: {
    en: 'Please select a item',
    zh: '请选择一项',
  },
  LABEL_UNITED_STATES: {
    en: 'United States',
    zh: '美国',
  },
  ERROR_EMPTY_COUNTRY: {
    en: 'Please select your billing country.',
    zh: '请选择您的账单地址国家',
  },
  LABEL_COUNTRY: {
    en: 'Country',
    zh: '国家',
  },
  LABEL_BILLING_ZIP: {
    en: 'Billing ZIP Code',
    zh: '账单地址邮政编码',
  },
  ERROR_BILLING_STATE: {
    en: 'Please select your billing state.',
    zh: '请选择您的账单地址所在州',
  },
  LABEL_STATE: {
    en: 'State',
    zh: '州',
  },
  MESSAGE_AGREE_TO_AGREEMENT: {
    en: 'By placing this order, I agree to the',
    zh: '下此订单意味着我同意',
  },
  LABEL_FF_RESERVATION_AGREEMENT: {
    en: 'FF Reservation Agreement',
    zh: 'FF预订协议',
  },
  LABEL_FF_ECOPRODUCT_AGREEMENT: {
    en: 'FF aiHypercar+ Preorder Agreement',
    zh: 'FF aiHypercar 预订协议'
  },
  ERROR_AGREE_TO_PROCEED: {
    en: 'You must agree to proceed.',
    zh: '您必须同意才能继续',
  },
  ERROR_READ_AND_AGREE: {
    en: 'Please read and agree to the above agreement.',
    zh: '请阅读并同意以上协议',
  },
  LABEL_DATE_SAVED: {
    en: 'Date Saved: ',
    zh: '保存日期: ',
  },
  LABEL_CONFIRMATION_NUMBER: {
    en: 'Confirmation Number: ',
    zh: '确认码: ',
  },
  LABEL_CONFIGURATION: {
    en: 'Configuration: ',
    zh: '配置: ',
  },
  LABEL_DETAILS: {
    en: 'Details',
    zh: '明细',
  },
  LABEL_DATE_RESERVED: {
    en: 'DATE RESERVED',
    zh: '时间',
  },
  LABEL_CONFIRMATION_NO: {
    en: 'CONFIRMATION #',
    zh: '确认码',
  },
  LABEL_ORDER_STATUS: {
    en: 'ORDER STATUS',
    zh: '状态',
  },
  LABEL_PAYMENT_CENTER: {
    en: 'Payment Center',
    zh: '收款台',
  },
  LABEL_ORDER_AMOUNT: {
    en: 'AMOUNT',
    zh: '金额',
  },
  LABEL_ORDER_CANCELLED: {
    en: 'Order has been cancelled',
    zh: '预定单已取消',
  },
  LABEL_ORDER_WAITLIST: {
    en: 'Alliance Edition Waitlist',
    zh: '联盟版候补名单',
  },
  LABEL_ORDER_JOINED_WAITLIST: {
    en: 'Joined',
    zh: '已加入',
  },
  LABEL_TOOLTIP_JOINED_WAITLIST: {
    en: `Your reservation is on the waitlist for the ${FF91_TEXT} Futurist Alliance Edition. We will notify you if your reservation becomes eligible for an upgrade.`,
    zh: `您已经加入${FF91_TEXT} 未来主义者联盟版候补名单。一旦候补成功，我们将与您联系。`,
  },
  LABEL_JOIN_ORDER_WAITLIST: {
    en: 'Join Waitlist',
    zh: '加入候补名单',
  },
  LABEL_TOOLTIP_JOIN_ORDER_WAITLIST: {
    en: `Join the waitlist for the ${FF91_TEXT} Futurist Alliance Edition. We will notify you if your reservation becomes eligible for an upgrade.`,
    zh: `加入${FF91_TEXT} 未来主义者联盟版候补名单。一旦候补成功，我们将与您联系。`,
  },
  LABEL_JOIN_ORDER_WAITLIST: {
    en: 'Join Waitlist',
    zh: '加入候补名单',
  },
  MESSAGE_SUCCESS_JOIN_WAITLIST: {
    en: `You have successfully joined the waitlist for ${FF91_TEXT} Futurist Alliance Edition.`,
    zh: `您成功加入了${FF91_TEXT} 未来主义者联盟版候补名单。`,
  },
  LABEL_PAYMENT_AMOUNT: {
    en: 'Amount you should pay',
    zh: '应付金额: ',
  },
  LABEL_WECHAT_PAY: {
    en: 'WeChat Pay',
    zh: '微信支付',
  },
  LABEL_ALI_PAY: {
    en: 'Ali Pay',
    zh: '支付宝支付',
  },
  LABEL_OPEN_WECHAT: {
    en: 'Open WeChat',
    zh: '打开手机端微信',
  },
  LABEL_OPEN_ALIPAY: {
    en: 'Open Alipay',
    zh: '打开支付宝钱包',
  },
  LABEL_SCAN_TO_PAY: {
    en: 'Scan QR code to pay',
    zh: '扫一扫继续付款',
  },
  LABEL_YUAN: {
    en: 'RMB',
    zh: ' 元',
  },
  LABEL_BANK_LIMITATION: {
    en: 'Check Amount Limitation',
    zh: '了解银行支付限额。查看限额 >',
  },
  LABEL_BANK_NAME: {
    en: 'Bank Name',
    zh: '银行类型',
  },
  LABEL_DEBIT_CARD: {
    en: 'Debit Card',
    zh: '储蓄卡',
  },
  LABEL_CREDIT_CARD: {
    en: 'Credit Card',
    zh: '信用卡',
  },
  LABEL_GS_BANK: {
    en: ' GS_Bank',
    zh: '工商银行',
  },
  LABEL_JS_BANK: {
    en: 'JS_Bank',
    zh: '建设银行',
  },
  LABEL_NY_BANK: {
    en: 'NY_Bank',
    zh: '农业银行',
  },
  LABEL_ZG_BANK: {
    en: 'ZG_Bank',
    zh: '中国银行',
  },
  LABEL_GD_BANK: {
    en: 'GD_Bank',
    zh: '光大银行',
  },
  LABEL_GF_BANK: {
    en: 'GF_Bank',
    zh: '广发银行',
  },
  LABEL_ZS_BANK: {
    en: 'ZS_Bank',
    zh: '招商银行',
  },
  LABEL_JT_BANK: {
    en: 'JT_Bank',
    zh: '交通银行',
  },
  LABEL_YZ_BANK: {
    en: 'YZ_Bank',
    zh: '邮政银行',
  },
  LABEL_ZX_BANK: {
    en: 'ZX_Bank',
    zh: '中信银行',
  },
  LABEL_MS_BANK: {
    en: 'MS_Bank',
    zh: '民生银行',
  },
  LABEL_XY_BANK: {
    en: 'XY_Bank',
    zh: '兴业银行',
  },
  LABEL_PA_BANK: {
    en: 'PA_Bank',
    zh: '平安银行',
  },
  LABEL_PF_BANK: {
    en: 'PF_Bank',
    zh: '浦发银行',
  },
  LABEL_HX_BANK: {
    en: 'HX_Bank',
    zh: '华夏银行',
  },
  LABEL_NB_BANK: {
    en: 'NB_Bank',
    zh: '宁波银行',
  },
  LABEL_SH_BANK: {
    en: 'SH_Bank',
    zh: '上海银行',
  },
  LABEL_YUAN_PER_DEAL: {
    en: 'RMB per deal',
    zh: '元/ 笔',
  },
  LABEL_YUAN_PER_DAY: {
    en: 'RMB per day',
    zh: '元/ 日',
  },
  LABEL_CARD_LIMITATION: {
    en: 'Credit limit',
    zh: '卡额度',
  },
  LABEL_NO_LIMIT: {
    en: 'No Limitation',
    zh: '无限额',
  },
  LABEL_NEXT_STEP: {
    en: 'Next Step',
    zh: '下一步',
  },
  MESSAGE_FOR_WECHAT_USER: {
    en: 'This is for WeChat user to use',
    zh: '推荐微信用户使用',
  },
  MESSAGE_FOR_ALIPAY_USER: {
    en: 'This is for AliPay user to use',
    zh: '推荐支付宝用户使用',
  },
  VIEW_DETAILS: {
    en: 'View Details',
    zh: '',
  },
  LABEL_RESERVE_TYPE: {
    en: 'RESERVATION TYPE',
    zh: '类型',
  },
  LABEL_PRIORITY_RESERVATION: {
    en: 'Priority Reservation',
    zh: '优先预订',
  },
  LABEL_FULLY_REFUNDABLE: {
    en: 'Fully refundable',
    zh: '全额可退',
  },
  LABEL_NON_REFUNDABLE: {
    en: 'Non refundable',
    zh: '不可退款'
  },
  MESSAGE_PRIORITY_RESERVE: {
    en: `A Priority Reservation makes you eligible to upgrade to the exclusive ${FF91_TEXT} Alliance Edition the first ${FF91_TEXT}s to be produced. This reservation requires a fully refundable $5,000 deposit.`,
    zh: `选择优先预订未来主义者版${FF91_TEXT}，并参加未来主义者体验官活动，成为全球首批体验${FF91_TEXT}的尊贵用户。`,
  },
  MESSAGE_PRIORITY_RESERVE_1: {
    en: `Be among the world's first to experience the ${FF91_TEXT} Futurist Edition, cocreate with FF through our FEO Program, and receive an equivalent award of $5,000 in FF Coins.`,
    zh: `成为全球首批体验${FF91_TEXT} Futurist 的尊贵用户，通过FEO项目参与价值共创，并获得价值￥50000的FF 币。`,
  },
  MESSAGE_PRIORITY_RESERVE_NOW_1: {
    en: `Reserve the groundbreaking ${FF91_TEXT} Futurist Edition, cocreate with FF through our FEO Program, and receive an equivalent award of $1,000 in FF Coins.`,
    zh: `预订${FF91_TEXT} Futurist，通过FEO项目参与价值共创，并获得价值￥10000的FF 币。`,
  },
  LABEL_ALLIANCE_EDITION: {
    en: 'THE ALLIANCE EDITION',
    zh: '梦想合伙人版',
  },
  MESSAGE_ALLIANCE_EDITION: {
    en: `This special launch variant of the ${FF91_TEXT} will be a limited edition. Each unit will be uniquely personalized to visually represent the distinction of being one of the first owners of an ${FF91_TEXT}. Further details will be revealed closer to the start of production.`,
    zh: `梦想合伙人版${FF91_TEXT}将限量发售。每台${FF91_TEXT}将采用与众不同的个性化定制，为首批${FF91_TEXT}车主带来直观可见的尊荣。更多细节将陆续公布。`,
  },
  DEPOSIT_AMOUNT: {
    en: '',
    zh: '订金金额',
  },
  DEPOSIT_PRIORITY_50000: {
    en: '',
    zh: '50,000元',
  },
  DEPOSIT_PRIORITY_10000: {
    en: '',
    zh: '10,000元',
  },
  MESSAGE_WECHAT_VERSION: {
    en: 'Please use WeChat Version 5 or higher to use Wechat scan payment',
    zh: '使用微信扫码支付，请使用5.0版本以上的微信',
  },
  MESSAGE_ALIPAY: {
    en: 'Please use Alipay wallet app to scan and make payment',
    zh: '使用支付宝钱包手机APP扫码支付',
  },
  LABEL_RESERVE_NOW: {
    en: 'RESERVE NOW',
    zh: '立即预约',
  },
  LABEL_RESERVE_NOW_FUTURIST: {
    en: 'RESERVE NOW',
    zh: '立即预订',
  },
  LABEL_RESERVE_STANDARD: {
    en: 'Standard',
    zh: '预约',
  },
  LABEL_RESERVE_PRIORITY: {
    en: 'Priority',
    zh: '预订',
  },
  LABEL_RESERVE_APPROVED_PRIORITY: {
    en: 'To pay',
    zh: '待支付',
  },
  LABEL_RESERVE_APPROVED_STANDARD: {
    en: 'Booking successfully',
    zh: '预约成功',
  },
  LABEL_RESERVE_REFUNDED: {
    en: 'Refunded',
    zh: '退款成功',
  },
  LABEL_RESERVE_CANCELLED: {
    en: 'Cancelled',
    zh: '取消',
  },
  LABEL_RESERVE_PAID: {
    en: 'Paid',
    zh: '预订成功',
  },
  LABEL_RESERVE_REFUNDING: {
    en: 'Refunding',
    zh: '退款中',
  },
  LABEL_CTA_PAY_THE_DEPOSIT: {
    en: 'Pay the deposit',
    zh: '支付订金',
  },
  LABEL_CTA_PAY_NOW: {
    en: 'Pay now',
    zh: '立即支付',
  },
  LABEL_CTA_APPLY_REFUND: {
    en: 'Apply refund',
    zh: '申请退款',
  },
  MESSAGE_LIMIT_QUANTITY: {
    en: 'Limited to 300 units with priority delivery',
    zh: '限量300台，优先交车',
  },
  MESSAGE_UNIQUE_UI: {
    en: 'Unique & personalized Alliance Edition digital interface',
    zh: '独特而个性化的梦想合伙人版专属数字界面',
  },
  MESSAGE_PROCEEDS_DONATE: {
    en: `A portion of ${FF91_TEXT} Alliance Edition sales proceeds will be donated for environmental protection`,
    zh: `${FF91_TEXT}梦想合伙人版销售额的一部分将用于捐助环保事业`,
  },
  MESSAGE_EXCLUSIVE_COLOR: {
    en: 'Exclusive exterior color: Silicon Valley Aluminum',
    zh: '专属外饰配色：硅谷铝',
  },
  MESSAGE_EXTERIOR_BADGING: {
    en: 'Unique Alliance Edition external badging',
    zh: '独有梦想合伙人版外徽标',
  },
  MESSAGE_MEMBERSHIP: {
    en: 'Exclusive Membership to our ‘Futurist Club’',
    zh: '专属塔尖俱乐部会籍',
  },
  LABEL_ECO_PRODUCT_PRICE: {
    en: 'Preorder $5,000',
    zh: '预订 ¥{price:=691}',
  },
  LABEL_STANDARD_RESERVATION: {
    en: 'Standard Reservation',
    zh: `预约属于您的${FF91_TEXT}`,
  },
  MESSAGE_CONFIRM_STANDARD_RESERVE: {
    en: `A Standard Reservation expresses your interest in reserving an ${FF91_TEXT} once Priority Reservations are available for collection. While this reservation does not secure a spot in line for an ${FF91_TEXT}, it is the best way to stay informed and express your interest in placing a deposit at a later date.`,
    zh: `提交${FF91_TEXT}预约申请，即刻享受以下权益：<br/>•  全球专属FFID<br/>• 最新产品/活动信息<br/>• 线上/线下销售活动参与权<br/>• 个性化定制服务`,
  },
  LABEL_PRIORITY_RESERVE_NOW: {
    en: 'Reserve Now!',
    zh: '即刻预订',
  },
  LABEL_ALLIANCE_INVITATION_CODE: {
    en: `Futurist Alliance Invitation Code is required to reserve your ${FF91_TEXT} Futurist Alliance Edition. If you need to apply the invitation code, please contact FF Co-creation Consultant team  (sales@ff.com) for instructions on how to qualify for it.`,
    zh: `获得${FF91_TEXT}未来主义者联盟版的优先预订权，需要未来主义者联盟邀请码。如果您需要申请邀请码，请联系FF共创顾问团队(400-700-9191)咨询邀请码的获取资格。`,
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_EDITION: {
    en: 'Alliance Edition',
    zh: '联盟版',
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_PRICE: {
    en: 'Priority Reservation $5,000',
    zh: '预订 ¥{price:=50,000}',
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_EDITION_BODY: {
    en: `Be among the world's first to experience the industry-leading 1,050 HP, 0-60 mph in less than 2.4 seconds and ultimate-intelligent tech luxury electric ${FF91_TEXT} Futurist Founder Edition. Your priority reservation makes you part of the exclusive group of futurists to own the vision of tomorrow.`,
    zh: `行业领先的1050匹马力，0-96公里每小时加速2.27秒。即刻预订拥有终极智能科技豪华电动车${FF91_TEXT}未来主义者联盟版，成为世界首批体验者，成为未来主义者的一员，尊享美好未来。`,
  },
  LABEL_PRIORITY_FF91_FUTURIST_ALLIANCE_EDITION_SOLDOUT: {
    en: `All 300 ${FF91_TEXT} Futurist Alliance Edition vehicles are sold out.`,
    zh: `全球限量300台的${FF91_TEXT} 未来主义者联盟版已售罄。但是您在预订${FF91_TEXT} 未来主义者版本时仍然可以选择加入候补名单。一旦候补成功，我们将与您联系。`,
  },
  LABEL_PRIORITY_FF91_FUTURIST: {
    en: `${FF91_TEXT} Futurist`,
    zh: `${FF91_TEXT} 未来主义者版`,
  },
  LABEL_PRIORITY_FF91_FUTURIST_SHORT: {
    en: `${FF91_TEXT} Futurist`,
    zh: `${FF91_TEXT} 未来主义者`,
  },
  LABEL_PRIORITY_FF91_FUTURIST_PRICE: {
    en: 'Reservation $1,500',
    zh: '预订 ¥{price:=20,000}',
  },
  LABEL_PRIORITY_FF91_PRICE: {
    en: 'Reservation $1,500',
    zh: '预订 ¥{price:=10,000}'
  },
  LABEL_PRIORITY_FF91_FUTURIST_BODY: {
    en: `The ${FF91_TEXT} Futurist is our class defining 1050 HP and a revolutionary user experience designed to create a mobile, connected, and luxurious third internet living space.`,
    zh: `${FF91_TEXT}未来主义者版本拥有1050匹马力，具有革命性的用户体验，旨在创造一个移动的，互联的，豪华的第三互联网生活空间。`,
  },
  LABEL_PRIORITY_FF91: {
    en: `${FF91_TEXT} (Coming soon)`,
    zh: `${FF91_TEXT} (即将开售)`,
  },
  LABEL_PRIORITY_FF91_TEXT: {
    en: `${FF91_TEXT}`,
    zh: `${FF91_TEXT}`
  },
  LABEL_PRIORITY_FF91_BODY: {
    en: `Be among the first to experience the class defining ultra-intelligent tech luxury electric ${FF91_TEXT}. Your reservation makes you part of the exclusive group of futurists to own the vision of tomorrow.`,
    zh: `成为世界上第一批体验终极智能科技奢华电动汽车${FF91_TEXT}的一员， 即可预订成为未来主义者，尊享美好未来。`,
  },
  MESSAGE_CONFIRM_RESERVE_NOW: {
    en: '',
    zh: `选择优先预订未来主义者版${FF91_TEXT}，并参加未来主义者体验官活动，成为全球首批体验${FF91_TEXT}的尊贵用户。<br/>享有人民币10000元的现金券用于购车使用<br/>`,
  },
  LABEL_BOOK_A_SPOT: {
    en: 'BOOK A SPOT NOW',
    zh: '现在加入预约队列',
  },
  LABEL_ACCOUNT_INFO: {
    en: 'Reservation Holder Account Information',
    zh: '预约用户账户信息',
  },
  MESSAGE_CANT_CHANGE_NAME: {
    en: 'Your First and Last name cannot be changed. For questions or assistance, please call us at (800) 228-7702.',
    zh: '您的姓氏和名字不能更改。如有问题或需要协助，请拨打(800) 228-7702联系我们',
  },
  MESSAGE_CAN_CHANGE_PHONE_ZIP: {
    en: 'You may change the phone number, ZIP code, and e-mail address linked to this reservation in',
    zh: '您可以更改本预订关联的电话号码和邮政编码，请前往：',
  },
  LABEL_PAGE: {
    en: 'page',
    zh: '页面',
  },
  LABEL_PAYMENT_BILLING: {
    en: 'Payment and Billing',
    zh: '付款与账单',
  },
  LABEL_RESERVE_YOUR_91: {
    en: `Reserve Your ${FF91_TEXT}`,
    zh: `${FF91_TEXT}开启全球预订`,
  },
  MESSAGE_CHOOSE_BETWEEN: {
    en: `You may choose between two types of reservations to ensure your place in line for future orders when ${FF91_TEXT} production begins.`,
    zh: `浏览下方信息，即刻成为${FF91_TEXT}的首批预订者。`,
  },
  LABEL_STEP_1: {
    en: 'STEP 1',
    zh: '步骤一',
  },
  LABEL_STEP_2: {
    en: 'STEP 2',
    zh: '步骤二',
  },
  LABEL_STEP_3: {
    en: 'STEP 3',
    zh: '步骤三',
  },
  LABEL_RESERVATION_TYPE: {
    en: 'Reservation Type',
    zh: '下单',
  },
  LABEL_BILLING_INFO: {
    en: 'Billing Information',
    zh: '提交',
  },
  MESSAGE_NO_MATCH_TITLE: {
    en: 'Oops...',
    zh: '啊哦',
  },
  MESSAGE_PHONE_NUMBER_VALID: {
    en: 'Please enter a valid phone number',
    zh: '请输入正确的手机号码',
  },
  MESSAGE_INVITATION_CODE_VALID: {
    en: 'Please enter a valid invitation code',
    zh: '请输入正确的未来主义者联盟邀请码',
  },
  MESSAGE_NO_MATCH_DETAILS: {
    en: 'Web page vanishes...',
    zh: '网页不见了!',
  },
  MESSAGE_THANK_YOU_RESERVATION: {
    en: 'Thank you for placing a',
    zh: '感谢',
  },
  MESSAGE_THANK_YOU_APPOINTMENT_STANDARD: {
    en: 'Thank you for your appointment',
    zh: '感谢您的预约',
  },
  MESSAGE_THANK_YOU_APPOINTMENT_PRIORITY: {
    en: 'Thank you for your reservation',
    zh: '感谢您的预订',
  },
  MESSAGE_THANK_YOU_RESERVATION_CONTINUE: {
    en: 'order.',
    zh: '',
  },
  MESSAGE_CONFIRMATION_NUMBER_IS: {
    en: 'Your confirmation number is:',
    zh: '您的确认号是：',
  },
  MESSAGE_CONFIRM_EMAIL_SENT: {
    en: 'A confirmation e-mail has been sent to',
    zh: '确认邮件已发送至',
  },
  MESSAGE_SEE_ALL_ORDERS: {
    en: 'See all your orders in your',
    zh: '要查看您的所有订单，请到您的：',
  },
  CHECK_ORDER: {
    en: 'Check Order',
    zh: '查看订单',
  },
  CONTACT_EMAIL: {
    en: 'contact email',
    zh: '联系邮箱',
  },
  MESSAGE_ORDER_SUCCESSFUL: {
    en: 'Order Status: Appointment Successful',
    zh: '订单状态: 预约成功',
  },
  MESSAGE_ORDER_STATUS: {
    en: 'Booking succeed',
    zh: '订单状态：预订成功',
  },
  MESSAGE_CONTACT: {
    en: 'Please keep the following information in a safe place. If you have any questions, please contact us.',
    zh: '以下信息请您妥善保存，如有疑问请联系我们。',
  },
  LABEL_RESET_PASSWORD: {
    en: 'RESET PASSWORD',
    zh: '重置密码',
  },
  MESSAGE_RESET_PASSWORD_REQUESTED: {
    en: 'You have requested to reset the password.',
    zh: '您已要求重置密码',
  },
  MESSAGE_NEW_PASSWORD_REQUIREMENTS: {
    en: 'The new password must be at least 8 characters and include at least one number, one uppercase and one lower case letter.',
    zh: '新密码必须至少8个字符，并包含至少1个数字、1个大写字母和1个小写字母',
  },
  LABEL_ERROR_404: {
    en: 'Error 404',
    zh: '404错误',
  },
  LABEL_ERROR_WECHAT_LOGIN: {
    en: 'WeChat login failed',
    zh: '微信登录失败',
  },
  LABEL_ERROR_SINAWEIBO_LOGIN: {
    en: 'Sina Weibo login failed',
    zh: '新浪微博登录失败',
  },
  MESSAGE_PAGE_NOT_EXISTS: {
    en: "The page you're looking for does not exist on this server",
    zh: '您查找的页面在本服务器上不存在',
  },
  LABEL_START_OVER: {
    en: 'START OVER',
    zh: '重新开始',
  },
  MESSAGE_FILL_IN_EMAIL: {
    en: 'Fill in your email address to have a link to reset your password sent to your inbox.',
    zh: '填写您的邮箱地址，发送重置密码链接至您的收件箱',
  },
  MESSAGE_FILL_IN_PHONE: {
    en: 'Please fill in your mobile to get verification code',
    zh: '填写您的电话以发送重置密码短信。',
  },
  MESSAGE_RESET_FILL_IN_PASSWORD: {
    en: 'You have requested to reset the password. The new password must be at least 8 characters and include at least one number, one uppercase, one lower case letter and 1 or more non-alphanumeric ASCII symbols: !@#$%^&+=-',
    zh: '长度必须至少为8个字符并且至少含有一个数字、一个大写字母，一个小写字母和一个特殊字符!@#$%^&+=-',
  },
  MESSAGE_SUCCESS_RESET_EMAIL_SENT: {
    en: 'Thanks! A link to reset your password has been sent to',
    zh: '谢谢！重置密码链接已发送至',
  },
  LABEL_SEND: {
    en: 'SEND',
    zh: '发送',
  },
  MARK_PERIOD: {
    en: '.',
    zh: '。',
  },
  MESSAGE_PLEASE_TRY_AGAIN: {
    en: 'Please try again.',
    zh: '请再试一次',
  },
  MESSAGE_FOOTER: {
    en: '© 2017 Faraday Future All rights reserved',
    zh: '© 2017 Faraday Future版权所有',
  },
  MESSAGE_THANK_YOU_ECOSYSTEM_PRODUCT_PREORDER: {
    en: 'Thank you for your preorder',
    zh: '感谢您的预购'
  },
  MESSAGE_OWNS_REFERRAL_CODE: {
    en: '',
    zh: '根据我们的引荐规则，您不能在预订时使用自己的引荐码。请使用其他人提供的引荐码或删除该引荐码。',
  },
  MESSAGE_REFERRAL_CODE_IS_ACCEPTED: {
    en: '',
    zh: '{name}的引荐码已被接受！交付车辆后，您将获得500,000 F 共创积分。',
  },
  MESSAGE_INVALID_REFERRAL_CODE: {
    en: '',
    zh: '引荐码不正确。请验证您的引荐码并重新输入或删除引荐码。',
  },
  MESSAGE_ORDER_WITH_REFERRAL_CODE: {
    en: '',
    zh: '通过使用引荐码购买，您将获得 500,000 F 共创积分。',
  },
  MY_FF_POINTS: {
    en: 'My FF points',
    zh: '我的积分',
  },

  PLEASE: {
    en: 'Please',
    zh: '请',
  },
  QR_CODE_EXPIRED: {
    en: 'QR Code expired.',
    zh: '二维码失效',
  },
  CLICK_TO_REFRESH: {
    en: 'click to refresh',
    zh: '点击刷新',
  },

  ////////////////// BREADCRUMBS //////////////////
  CRUMBS_MY_FF: {
    en: 'My FF',
    zh: '我的FF',
  },
  CRUMBS_MY_ACCOUNT: {
    en: 'My Account',
    zh: '我的账户',
  },
  CRUMBS_MY_REFERRALS: {
    en: 'My Referrals',
    zh: 'FF 引荐计划',
  },
  CRUMBS_MY_FF_POINTS: {
    en: 'My FF Points',
    zh: '未来主义者共创积分',
  },
  CRUMBS_MY_DESIGNS: {
    en: 'My Designs',
    zh: '我的设计',
  },
  CRUMBS_DESIGN_DETAILS: {
    en: 'Details',
    zh: '配置明细',
  },
  CRUMBS_LOGIN: {
    en: 'Login',
    zh: '登录',
  },
  CRUMBS_SUCCESS: {
    en: 'Success',
    zh: '成功',
  },
  CRUMBS_CHANGE_PASS: {
    en: 'Change Password',
    zh: '更改密码',
  },
  CRUMBS_FORGOT_PASS: {
    en: 'Forgot Password',
    zh: '忘记密码',
  },
  CRUMBS_RESET_PASS: {
    en: 'Reset Password',
    zh: '重设密码',
  },
  CRUMBS_RESERVE: {
    en: 'Reserve',
    zh: '订单类型',
  },
  CRUMBS_STANDARD: {
    en: 'Standard Reservation',
    zh: '预约',
  },
  CRUMBS_PREORDER: {
    en: 'Priority Reservation',
    zh: '优先预订',
  },
  CRUMBS_RESERVE_SUCCESS_BASE: {
    en: 'Success',
    zh: '成功',
  },
  CRUMBS_MY_RESERVATIONS: {
    en: 'My Reservations',
    zh: '我的预订',
  },
  CRUMBS_RESERVE_DETAILS: {
    en: 'Details',
    zh: '详细',
  },
  CRUMBS_MAKE_PAYMENT: {
    en: 'Make Payment',
    zh: '收款台',
  },
  CRUMBS_FFAIHYPERCARPLUS: {
    en: 'FF aiHypercar+',
    zh: 'FF aiHypercar+'
  },
  CRUMBS_FFAIHYPERCARPLUS_DETAILS: {
    en: 'FF aiHypercar+ Details',
    zh: 'FF aiHypercar+ 详细'
  },
  CRUMBS_MY_ECOSYSTEM_PRODUCT: {
    en: 'FF aiHypercar+',
    zh: 'FF aiHypercar+'
  },
  CRUMBS_ECOSYSTEM_PRODUCT_DETAILS: {
    en: 'EcoSystem Product Details',
    zh: '出行生态产品详情'
  },
  LABEL_RESERVATION_DETAILS: {
    en: 'Reservation Details',
    zh: '订单详情',
  },
  LABEL_DESIGN_DETAILS: {
    en: 'Design Details',
    zh: '配置明细',
  },
  LABEL_FF91_PRIORITY_RESERVATION: {
    en: `${FF91_TEXT} Priority Reservation`,
    zh: `${FF91_TEXT}优先预订`,
  },
  LABEL_FF91_RESERVATION: {
    en: `${FF91_TEXT} Reservation`,
    zh: `${FF91_TEXT}预订`,
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_START_TITLE: {
    en: 'Would you like to register as a FPO?',
    zh: '您是否想加入未来主义者产品官(FPO)计划？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_START_SUBTITLE: {
    en: `We would like to invite you to join an exclusive experience designed by Faraday Future – our Futurst Product Officer program. Here, you’ll have a highly customized and hands-on experience with ${FF91_TEXT} so you can help us shape the future of the mobility industry.`,
    zh: `我们诚邀您加入由 Faraday Future 设计的独家体验——我们的未来主义者产品官(FPO)计划。在这里，您将获得高度定制的 ${FF91_TEXT} 亲身体验，从而帮助我们塑造移动行业的未来。`,
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_FORM_TITLE: {
    en: '',
    zh: '申请加入未来主义者产品官(FPO)计划',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_FORM_SUBTITLE: {
    en: '',
    zh: '注册加入未来主义者产品官(FPO)计划，您将获得Faraday Future设计的独家体验，通过反馈想法，您有机会塑造未来移动行业，您也将有机会直接与FF的工程师和设计团队合作，把想法和创造力变成现实。',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_MAKE_MODEL: {
    en: '',
    zh: '您目前在使用的是什么车？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_MAKE_MODEL_ERROR_MESSAGE: {
    en: '',
    zh: '请输入您目前在使用的车',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TIME_SPENT_IN_CAR: {
    en: '',
    zh: '您每天在车内的时间',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TIME_SPENT_IN_CAR_ERROR_MESSAGE:
    {
      en: '',
      zh: '请选择您每天在车内的时间',
    },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TIME_RANGE_LESS_THAN_30_MIN: {
    en: '',
    zh: '0分钟 - 30分钟',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TIME_RANGE_30_MIN_TO_1_HOUR: {
    en: '',
    zh: '30分钟 - 1小时',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TIME_RANGE_1_HOUR_TO_2_HOUR: {
    en: '',
    zh: '1小时 - 2小时',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TIME_RANGE_MORE_THAN_2_HOUR: {
    en: '',
    zh: '2小时以上',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_CAR_NEWS_CHANNEL: {
    en: '',
    zh: '您获取汽车信息的渠道有哪些？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_CAR_NEWS_CHANNEL_ERROR_MESSAGE: {
    en: '',
    zh: '请选择您获取汽车信息的渠道',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_CAR_NEWS_CHANNEL_1: {
    en: '',
    zh: '汽车之家，懂车帝等垂媒',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_CAR_NEWS_CHANNEL_2: {
    en: '',
    zh: '大众消费类APP，比如抖音，小红书',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_CAR_NEWS_CHANNEL_3: {
    en: '',
    zh: '知识分享类APP，比如知乎',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_CAR_NEWS_CHANNEL_OTHER: {
    en: '',
    zh: '其他',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS: {
    en: '',
    zh: '您感兴趣的共创主题有哪些？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS_ERROR_MESSAGE: {
    en: '',
    zh: '请选择您感兴趣的共创主题',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS_PERFORMANCE: {
    en: '',
    zh: '性能',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS_INTELLIGENCE: {
    en: '',
    zh: '智能',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS_TECHNOLOGY: {
    en: '',
    zh: '科技',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS_LUXURY: {
    en: '',
    zh: '奢侈',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_TOPICS_OTHER: {
    en: '',
    zh: '其他',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_EV_REASON: {
    en: '',
    zh: '您为什么购买电动车？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_EV_REASON_ERROR_MESSAGE: {
    en: '',
    zh: '请输入您购买电动车的原因',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_EXPECTING: {
    en: '',
    zh: '您对于FF产品有哪些期待？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_EXPECTING_ERROR_MESSAGE: {
    en: '',
    zh: '请输入您对FF产品的期待',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DELIVERY_METHOD: {
    en: '',
    zh: '您更喜欢以下哪种交车方式？',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DELIVERY_METHOD_ERROR_MESSAGE: {
    en: '',
    zh: '请选择您喜欢的交车方式',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DELIVERY_METHOD_HOME_DELIVERY: {
    en: '',
    zh: '送车到家',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DELIVERY_METHOD_STORE_PICKUP: {
    en: '',
    zh: '到店交车仪式',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DELIVERY_METHOD_OTHER: {
    en: '',
    zh: '其他',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_AGREE: {
    en: '',
    zh: '加入未来主义者产品官(FPO)计划的条款',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_AGREE_ERROR_MESSAGE: {
    en: '',
    zh: '请选择同意加入未来主义者产品官(FPO)计划条款',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_SUCCESS_TITLE: {
    en: '',
    zh: '恭喜！',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_SUCCESS_SUBTITLE: {
    en: '',
    zh: '您已经正式加入未来主义者产品官(FPO)计划。',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_DOWNLOAD_DESCRIPTION: {
    en: '',
    zh: '马上下载FF Intelligence APP, 分享您的创意和建议。',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_MODAL_ALREADY_FPO_TITLE: {
    en: '',
    zh: '您已经成功加入了未来主义者产品官(FPO)计划',
  },
  LABEL_BACK_TO_RESERVE: {
    en: 'BACK TO RESERVATIONS',
    zh: '返回订单页',
  },
  LABEL_HOUR_MINUTE: {
    en: '{hour} hour {minute} minute',
    zh: '{hour}小时{minute}分钟',
  },
  LABEL_RESERVATION_HOLDER: {
    en: 'Reservation Holder',
    zh: '预订人信息',
  },
  LABEL_PRIORITY_FF91_CANT_WAIT: {
    en: `Can't wait? Reserve the ${FF91_TEXT} Futurist now`,
    zh: `迫不及待想体验? 立刻预订${FF91_TEXT}未来主义者版吧!`,
  },
  LABEL_RESERVATION_TYPE_COMING_SOON: {
    en: 'Coming Soon',
    zh: '即将推出',
  },
  LABEL_RESERVATION_TYPE_SOLD_OUT: {
    en: 'SOLD OUT',
    zh: '已售罄',
  },
  LABEL_RESERVATION_TYPE_SELECT: {
    en: 'Select',
    zh: '选择',
  },
  LABEL_RESERVATION_TYPE_SELECTED: {
    en: 'Selected',
    zh: '已选',
  },
  LABEL_PAYMENT_SUM: {
    en: 'Payment Summary',
    zh: '付款信息',
  },
  LABEL_ITEM_PRICE: {
    en: 'Item Price',
    zh: 'Item Price',
  },
  LABEL_OPEN_APP: {
    en: 'Open App',
    zh: '打开 FF App'
  },
  LABEL_PAYMENT: {
    en: 'Payment',
    zh: 'Payment',
  },
  LABEL_BALANCE_DUE: {
    en: 'Balance Due',
    zh: 'Balance Due',
  },
  LABEL_PAYMENT_CHANNEL: {
    en: 'Payment Channel',
    zh: '支付渠道',
  },
  LABEL_PAYMENT_SEQ_NUMBER: {
    en: 'Transaction Number',
    zh: '支付凭证号',
  },
  LABEL_PAYMENT_AMOUNT: {
    en: 'Payment Amount',
    zh: '付款金额',
  },
  MESSAGE_QUESTION_ORDER_1: {
    en: 'If you have any questions with your order, please call us at (800) 228-7702 or email us at',
    zh: '如果您对您的订单有任何疑问，请发送电子邮件到 ',
  },
  MESSAGE_QUESTION_ORDER_2: {
    en: '.',
    zh: '与我们联系。',
  },
  PLEASE_WAIT: {
    en: 'Please wait...',
    zh: '请等待...',
  },
  MESSAGE_NO_RESERVATION_1: {
    en: `Thank you for your interest in reserving ${FF91_TEXT}. Please email our sales team at`,
    zh: '',
  },
  MESSAGE_NO_RESERVATION_2: {
    en: 'and we will follow up with you in the next 24 hours to finalize your reservation.',
    zh: '',
  },
  LABEL_PAYMENT_TITLE: {
    en: 'Congratulations, your submission is success!',
    zh: '恭喜您，提交成功！',
  },
  LABEL_DISMISS: {
    en: 'Dismiss',
    zh: '',
  },
  LABEL_CHAPTER_I_COMPLETE: {
    en: 'Chapter I Complete',
    zh: '',
  },
  LABEL_BOOK_NOW: {
    en: 'Book Now',
    zh: '立即预订',
  },
  LABEL_DELIVERY: {
    en: 'Delivery',
    zh: '交车',
  },
  LABEL_CANCELLATION: {
    en: 'Cancellation',
    zh: '取消',
  },
  LABEL_ORDER_NUMBER: {
    en: 'Order Number',
    zh: '订单号：',
  },
  TITLE_DELIVERY_CANCELLATION_POLICY: {
    en: 'Delivery & Cancellation',
    zh: '交车/取消',
  },
  TITLE_ECOPRODUCT_DISCLAIMER: {
    en: 'Disclaimer',
    zh: '免责声明',
  },
  MESSAGE_ECOPRODUCT_DISCLAIMER: {
    en: `To truly own ecological membership products, users need to continue to pay the remaining balance and complete the purchase`,
    zh: '真正拥有生态会员产品，需要用户继续支付剩余余额，完成购买',
  },
  MESSAGE_DELIVERY_CANCELLATION_POLICY: {
    en: `Although we are making all efforts to deliver the ${FF91_TEXT} as soon as reasonably possible, you understand and agree that placing this reservation does not guarantee receipt of an ${FF91_TEXT} or a specific delivery date. You are free to cancel this reservation at any time prior to notice from us that your ${FF91_TEXT} Is ready for delivery.  If you do so prior to our notice of delivery, you will obtain a full refund of any deposit.`,
    zh: '在您预订的车辆生产之前，我们将与您联系，您可以对车型进行个性化定制及选配，并签署相应的车辆销售合同。在签署车辆销售合同前，您可随时取消预订，订金将全额返还。',
  },
  MESSSAGE_YOU_HAVE: {
    en: 'You have',
    zh: '',
  },
  MESSAGE_MORE_ATTEMPTS_LOGIN: {
    en: 'more attempts before your account is locked for 20 minutes.',
    zh: '您还可以 `${}` 次重试, 然后账号将被锁定20分钟',
  },
  MESSAGE_RESET_PASSWORD: {
    en: 'You may reset your password',
    zh: '你可以重置您的密码.',
  },
  MESSAGE_LIMITED_ACCESS: {
    en: 'Thank you for your interest in Faraday Future. We apologize for the inconvenience, but registration is not currently available in your country or region at this time. Please follow Faraday Future on social media for the latest vehicle and company updates.',
    zh: '',
  },
  LABEL_TEST_RIDE: {
    en: `${FF91_TEXT} Test Ride`,
    zh: '',
  },
  MESSAGE_TEST_RIDE_DESCRIPTION: {
    en: 'Welcome and thank you for your interest in Faraday Future. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi magni error enim incidunt. Repellendus, neque dolorem vel omnis provident quasi magnam voluptatum et in facere dignissimos quibusdam aliquam sequi. Quos.',
    zh: '',
  },
  PLACEHOLDER: {
    en: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. At unde assumenda tenetur, mollitia corporis nostrum ex architecto accusamus. Atque dolorem iusto architecto quos eveniet blanditiis nihil doloribus impedit voluptatum fugit.',
    zh: '',
  },
  LABEL_TEST_RIDE_POLICY: {
    en: 'Test Ride Policy',
    zh: '',
  },
  LABEL_PLACEHOLDER: {
    en: 'Past Test Ride Content',
    zh: '',
  },
  LABEL_QUESION_HAVE_INVITATION_CODE: {
    en: 'Do you have an invitation code?',
    zh: '',
  },
  LABEL_HAVE_CODE: {
    en: 'I have a code',
    zh: '',
  },
  LABEL_NO_CODE: {
    en: 'I do not have a code',
    zh: '',
  },
  LABEL_INVITATION_CODE: {
    en: 'Invitation Code',
    zh: '',
  },
  ERROR_INVALID_INVITATION_CODE: {
    en: 'Invalid Invitation Code',
    zh: '',
  },
  ERROR_REQUIRED_FIELD: {
    en: 'This field is required',
    zh: '',
  },
  LABEL_PREFERRED_CONTACT_METHOD: {
    en: 'Preferred Contact Method',
    zh: '',
  },
  LABEL_PHONE: {
    en: 'Phone',
    zh: '',
  },
  MESSAGE_PROMPT_CREATE_PASSWORD: {
    en: 'Create a password to complete your My FF account. This password will be used for our website and our app. Must be at least 8 characters including 1 or more numbers.',
    zh: '',
  },
  MESSAGE_AGREE_TO_FF: {
    en: "I agree to FF's",
    zh: '',
  },
  LABEL_TERMS_OF_SERVICES: {
    en: 'Terms of Service',
    zh: '',
  },
  LABEL_CONTINUE: {
    en: 'continue',
    zh: '继续',
  },
  ERROR_MATCH_PASSWORD: {
    en: 'The passwords do not match!',
    zh: '密码不匹配',
  },
  ERROR_FETCH_USER_PHONE: {
    en: "Could not fetch the user's phone number",
    zh: '无法找到用户手机号',
  },
  WARNING_MAX_RESERVE: {
    en: 'You have reached the maximum number of priority reservations',
    zh: '您的账户预订数量已经达到上限。想了解更多，请您关注后续活动。',
  },
  ////////////////RefundReasons/////////////
  LABEL_REFUND_TITLE: {
    en: 'Please choose the reason of your refund',
    zh: '请您选择退款原因',
  },
  REFUND_REASON_NOT_WANT_TO_PURCHASE: {
    en: 'Do not want to purchase for now',
    zh: '暂时不想购买',
  },
  REFUND_REASON_DIFFICULT_TO_PURCHASE: {
    en: 'Difficult to register, pay and check reservation',
    zh: '注册、支付、订单查询困难',
  },
  REFUND_REASON_INFORMATION_NOT_CLEAR: {
    en: 'Information for the price, equipment and following up activities is not clear',
    zh: '产品价格、配置、后续活动等信息不明确',
  },
  REFUND_REASON_TOO_LONG_TO_DELIVER: {
    en: 'Take too long to deliver the car',
    zh: '交车时间过长',
  },
  REFUND_REASON_LACK_OF_CONFIDENCE: {
    en: 'Follow up marketing information affect confidence to purchase',
    zh: '后续获取市场信息有限影响购买信心',
  },
  REFUND_REASON_LOW_CONSULTATION_EFFICIENCY: {
    en: 'User consultation method is too limit, efficiency is low',
    zh: '用户咨询方式过于单一，时效性不强',
  },
  REFUND_REASON_OTHER: {
    en: 'Other reasons',
    zh: '其他原因',
  },
  LABEL_REFUND_REASON_COMMENT: {
    en: 'Comment (Optional)',
    zh: '备注说明（选填）',
  },
  LABEL_REFUND_REASON_WORD_LIMIT: {
    en: 'Please enter less than 100 characters',
    zh: '请输入100字以内文字',
  },
  LABEL_REFUND_BUTTON: {
    en: 'Confirm refund',
    zh: '确认退款',
  },
  LABEL_REFUND_NO_REFUND: {
    en: 'Cancel refund',
    zh: '取消退款',
  },
  LABEL_REFUND_CONFIRM: {
    en: 'Honored FF customer, your refund request has been submitted, customer service will get conntact with you ASAP,thank you for your support',
    zh: '尊敬的FF用户， 您的退订申请已提交，客服将尽快联系您，核对相关信息，感谢您对FF的关注与支持。',
  },
  LABEL_REFUND_PROCESSING: {
    en: 'Refunding',
    zh: '退款中',
  },
  LABEL_REFUND_ERROR: {
    en: 'System Error',
    zh: '系统错误',
  },
  LABEL_CUSTOMER_INFORMATION: {
    en: 'Customer Information',
    zh: '账户信息',
  },
  LABEL_RESERVATION_INFORMATION: {
    en: 'Reservation Information',
    zh: '预订信息',
  },
  LABEL_RESERVE_PAYMENT_MESSAGE: {
    en: '',
    zh: '点击此按钮本身不会产生任何扣款',
  },
  ERROR_SERVER: {
    en: 'Server Error. Please try again later.',
    zh: '系统错误，请稍后再试',
  },
  LABEL_WECHAT_CLICK_BUTTON_TEXT_1: {
    en: 'Click button',
    zh: '点击右上角的',
  },
  LABEL_WECHAT_CLICK_BUTTON_TEXT_2: {
    en: 'on the top',
    zh: '按钮',
  },
  LABEL_WECHAT_CHOOSE_BROWSER_TEXT_1: {
    en: 'Choose',
    zh: '选择',
  },
  LABEL_WECHAT_CHOOSE_BROWSER_TEXT_2: {
    en: 'open browser',
    zh: '在浏览器打开',
  },
  LABEL_BACK: {
    en: 'Back',
    zh: '返回',
  },
  LABEL_USER_INFORMATION: {
    en: 'User Information',
    zh: '用户信息',
  },
  LABEL_REGION_DEFAULT: {
    en: 'Select region',
    zh: '选择地区',
  },
  LABEL_NEXT: {
    en: 'Next',
    zh: '下一步',
  },
  LABEL_LEARN_MORE: {
    en: 'Learn more',
    zh: '查看详情',
  },
  LABEL_FUTURIST_PROGRAMS: {
    en: 'Futurist Programs',
    zh: '未来主义者项目',
  },
  LABEL_COCREATION_POINTS_PROGRAM: {
    en: 'Co-creation Points program',
    zh: '未来主义者共创积分项目',
  },
  LABEL_COCREATION_POINTS_PROGRAM_CONTENT_1: {
    en: "FF Co-creation Point (CP) is used to award user's participation in FF value co-creating activities and plays an important role in FF User Ecosystem.",
    zh: '未来主义者共创积分用于奖励用户参与FF价值共创的行为，在FF用户生态中发挥重要作用。',
  },
  LABEL_COCREATION_POINTS_PROGRAM_CONTENT_2: {
    en: 'Your CP will be issued to and stored in your FF account and can be viewed through My FF.',
    zh: '您的共创积分将被发放并保存在您的FF账户内，并通过“我的FF”查看。',
  },
  LABEL_LEARN_MORE_TERMS_OF_USE_CONTENT_1: {
    en: "The CP you receive from reserving the vehicle will be automatically activated once you've successfully paid off the vehicle price.",
    zh: '您通过车辆预订所获得的共创积分将在您成功支付全部购车款后自动被激活。',
  },
  LABEL_LEARN_MORE_TERMS_OF_USE_CONTENT_2: {
    en: 'The CP is valid for two years after being activated.',
    zh: '共创积分将在被激活后两年内有效。',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM: {
    en: 'Futurist Product Officer Program',
    zh: '未来主义者产品官计划',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_CONTENT_1: {
    en: 'Futurist Product Officer (FPO) program is a value co-creation platform between FF and users.',
    zh: '未来主义者产品官计划（FPO）搭建了FF和用户一起价值共创的平台。',
  },
  LABEL_FUTURIST_PRODUCT_OFFICER_PROGRAM_CONTENT_2: {
    en: 'The FPOs contribute to the creation of the future of mobility by participating in product testing & feedback, new ideas generation and communication.',
    zh: '让用户通过产品测试，反馈，创意和传播等方式，共同定义人类未来出行生态。',
  },
  LABEL_END_USER_AGREEMENT: {
    en: 'End User License Agreement',
    zh: `${FF91_TEXT}预订协议`,
  },
  LABEL_DELIVERY_AND_CANCELLATION: {
    en: 'Delivery & Cancellation',
    zh: '交车/取消',
  },
  LABEL_WAIT_PAYMENT: {
    en: 'Wait Payment',
    zh: '等待付款',
  },
  LEARN_MORE: {
    en: 'Learn More',
    zh: '了解更多',
  },
  MESSAGE_APPLY_REFERRAL: {
    en: '',
    zh: '您可以引荐好友购车，每个成功引荐可获得 500,000 F 共创积分奖励，通过您的引荐购车的家人和朋友也将最高可获得500,000 F 共创积分奖励。引荐奖励将在车辆交付后全部生效。',
  },
  MESSAGE_APPLY_REFERRAL_2: {
    en: '',
    zh: '享受极致科技奢华的 FF 91 2.0 系列产品，并获得高额共创积分奖励。立即申请成为引荐人并引荐您的好友吧。',
  },
  BUTTON_APPLY_REFERRAL: {
    en: 'Apply to become a Referrer',
    zh: '申请成为引荐人'
  },
  MESSAGE_INELIGIBLE_REFERRAL_TITLE: {
    en: 'I&#39;m sorry, you cannot become a Referrer at this time.',
    zh: '对不起，您现在不能成为推荐人'
  },
  MESSAGE_INELIGIBLE_REFERRAL_DESCRIPTION: {
    en: 'Only FF owners and preorder holders can become referrers.You can purchase a car through their referral and receive 150,000F for consumption of FF ecosystem products.',
    zh: '只有FF所有者和预订持有人可以成为推荐人。您可以通过他们的推荐购买汽车并获得150,000F用于消费FF生态系统产品。'
  },
  LABEL_AGREE_REFERRAL_AGREENMENT: {
    en: 'I agree to the above agreement.',
    zh: '我同意上述协议。'
  },
  BUTTON_AGREE_AND_SIGN_REFERRAL_AGREENMENT: {
    en: 'Agree and sign the agreement',
    zh: '同意并签署协议'
  },
  LABEL_MY_REFERRAL_TITLE: {
    en: 'Earn Referral Credits Make referrals, get credits',
    zh: '引荐好友购车，赚取共创积分'
  },
  LABEL_YOUR_REFERRAL_CODE: {
    en: 'Your Referral Code',
    zh: '您的引荐码'
  },
  BUTTON_REFER_CTA: {
    en: 'Refer',
    zh: '引荐'
  },
  LABEL_ECOSYSTEM_PRODUCT: {
    en: 'EcoSystem Product',
    zh: '出行生态产品'
  },
  LABEL_FFAIHYPERCARPLUS: {
    en: 'FF aiHypercar+',
    zh: 'FF aiHypercar+'
  },
  MESSAGE_MY_ECOSYSTEM_PRODUCT: {
    en: 'View EcoSystem Product',
    zh: '查看出行生态产品'
  },
  LABEL_FFAIHYPERCARPLUS_PREORDER_AGREEMENT: {
    en: 'FF aiHypercar+ Preorder Agreement',
    zh: 'FF aiHypercar+ 预订协议'
  },
  LABEL_POINTS_USAGE_RULE: {
    en: 'Points usage Rule',
    zh: '积分使用规则'
  },
  LABEL_POINTS_EVENT: {
    en: 'Event',
    zh: '事项',
  },
  LABEL_POINTS_DATE: {
    en: 'Date',
    zh: '日期',
  },
  LABEL_POINTS_AMOUNT: {
    en: 'Amount',
    zh: '数量'
  },
  LABEL_POINTS_TOTAL: {
    en: 'Total',
    zh: '总数',
  },
  LABEL_POINTS_PENDING: {
    en: 'Pending',
    zh: '待入帐',
  },
  ECO_REFUND_REASON_BAD_DESCRIPTION: {
    en: 'Bad product description',
    zh: '产品包内容不了解，不清晰',
  },
  ECO_REFUND_REASON_HIGH_PRICE: {
    en: 'High price',
    zh: '定价过高，产品价值与价格不符',
  },
  ECO_REFUND_REASON_WAIT_TOO_LONG: {
    en: 'Wait too long',
    zh: '预售产品等待时间过长',
  },
  ECO_REFUND_REASON_PERSONAL_REASON: {
    en: 'Personal reason',
    zh: '个人原因',
  },
  ECO_REFUND_REASON_NO_VEHICLE: {
    en: 'No vehicle',
    zh: '没有购车，产品无法使用',
  },
  ECO_REFUND_REASON_OTHER: {
    en: 'Other',
    zh: '其他原因'
  }
};
