import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';
import BodyText from './typography/BodyText';

class ExpansionPanel extends React.Component {
  state = {
    expanded: false
  }
  childrenHeight = 0;

  toggle = () => {
    this.setState(state => ({
      expanded: !state.expanded
    }));
  }

  componentDidMount = () => {
    if (this.childNode) {
      this.childrenHeight = this.childNode.offsetHeight;
    }
  }

  getElemHeight = () => {
    if (this.state.expanded) {
      return { height: `${this.childrenHeight.toString()}px` };
    }

    return { height: 0 };
  };

  render() {
    const { className, classes: c, title, children } = this.props;
    const { expanded } = this.state;

    return (
      <div className={cx(className, c.container)}>
        <div className={c.titleRow} onClick={this.toggle}>
          <BodyText className={c.bold}>{title}</BodyText>
          <span
            className={cx('ff-icons-expand-more', expanded && c.reversed)}
          />
        </div>
        <div
          className={c.collapsed}
          style={this.getElemHeight()}
        >
          <div
            className={c.textBlock}
            ref={(childNode) => {
              if (!this.childNode) {
                this.childNode = childNode;
              }
            }}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    borderBottom: '1px #bbb solid',
    paddingBottom: '10px',
    marginBottom: '50px'
  },
  bold: {
    color: '#666',
    fontWeight: '500'
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& > .ff-icons-expand-more': {
      fontSize: '8px',
      transition: 'transform .2s ease-in-out'
    }
  },
  collapsed: {
    overflow: 'hidden',
    transition: 'height .2s ease-in-out',
    height: '0'
  },
  reversed: {
    transform: 'rotate(-180deg)'
  },
  textBlock: {
    padding: '5px'
  }
};

export default injectSheet(styles)(ExpansionPanel);
