const footerPropsCN = {
  footerRegion: "CN",
  regions: [
    {
      imgUrl: "https://genesis-cdn.faradayfuturecn.com/icon-en.png",
      value: "US",
      domain: ""
    },
    {
      imgUrl: "https://genesis-cdn.faradayfuturecn.com/icon-china.png",
      value: "CN",
      domain: ""
    }
  ],
  items: [
    {
      sectionTitle: "ABOUT US",
      links: [
        {
          url: "/our-company",
          label: "Our Company"
        },
        {
          url: "/our-approach",
          label: "Our Approach"
        },
        {
          url: "/our-team",
          label: "Our Team"
        },
        {
          url: "/press-room",
          label: "Press Room"
        },
        {
          url: "/futuresight",
          label: "Blog"
        },
        {
          url: "/ff-zero-1",
          label: "FF Zero 1"
        },
        {
          url: "/gallery",
          label: "FF Gallery"
        },
        {
          url: "/solutions",
          label: "Solutions"
        },
        {
          url: "https://investors.ff.com",
          label: "Investors"
        }
      ]
    },
    {
      sectionTitle: "SUPPORT",
      links: [
        {
          url: "/contact-us",
          label: "Contact Us"
        },
        {
          url: "/frequently-asked-questions",
          label: "FAQ"
        },
        {
          url: "/careers",
          label: "Careers"
        },
        {
          url: "/sitemap",
          label: "Sitemap"
        },
        {
          url: "/privacy-policy",
          label: "Privacy Policy"
        },
        {
          url: "/terms-of-use",
          label: "Terms of Use"
        }
      ]
    }
  ],
  socialLinks: [
    {
      url: "http://weibo.com/u/5801670517",
      icon: "ff-icon-social-weibo"
    },
    {
      url: "https://genesis-cdn.faradayfuturecn.com/WeChat%20QR%20code.jpg",
      icon: "ff-icon-social-wechat"
    }
  ],
  copyright: {
    text: "Faraday&Future Inc. © {year}",
    link_url: "https://beian.miit.gov.cn",
    link_text: "京ICP备19018825号-1",
    show_horizontal_alignment: true,
    moreLinks: [
      {
        url: "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502045145",
        text: "京公网安备 11010502045145号",
        icon_url: "https://genesis-cdn.faradayfuturecn.com/beian-icon.png"
      }
    ]
  },
  download: {
    url: "https://www.ff.com/us/mobile-app",
    title: "DOWNLOAD FF INTELLIGENT APP",
    appButtonText: "INSTALL APP",
    iosStoreLink: "https://itunes.apple.com/us/app/id1600637235?ls=1&mt=8",
    iosAppLink: "https://faradayfuture2023.app.link/YajHk0j7Nxb",
    iosImage: "",
    androidStoreLink: "https://play.google.com/store/apps/details?id=com.faradayfuture.online.foreign",
    androidAppLink: "faradayfuture://feed",
    androidApkLink: "",
    androidImage: ""
  },
  subscribeForm: {
    enabled: true,
    submitUrl: "/api/subscribe/",
    title: "Get the latest news and important updates from FF",
    subtitle: "Sign up for the latest of the FF 91 Futurist and other news about Faraday Future",
    inputPlaceholder: "Email*",
    inputErrorMessage: "Please provider a valid email",
    serverErrorMessage: "Something went wrong. Please try again later.",
    successMessage: "Your subscription has been confirmed. You’ll receive the latest news and updates from Faraday Future.",
    button: "Subscribe →",
    margin: "10px 0px"
  },
  navLinks: [
    {
      links: [
        {
          text: "FF 91 2.0 未来主义者联盟版",
          url: "/cn/product/ff-91-Alliance/"
        },
        {
          text: "FF 91 2.0 未来主义者版",
          url: "/cn/product/ff-91-Futurist/"
        },
        {
          text: "FF 91 2.0",
          url: "/cn/preorder?edition=ff91-standard"
        },
        {
          text: "FF aiHypercar+",
          url: "/cn/ecosystem-product"
        }
      ]
    },
    {
      links: [
        {
          text: "直播&活动",
          url: "/cn/91launch/"
        },
        {
          text: "新闻室",
          url: "/cn/press-room/"
        },
        {
          text: "投资者关系",
          url: "https://investors.ff.com/"
        }
      ]
    },
    {
      links: [
        {
          text: "商城",
          url: "https://shop.ff.com/pages/cn"
        },
        {
          text: "联系我们",
          url: "/cn/contact-us/"
        },
        {
          text: "我的账户",
          url: "/cn/MyFF"
        }
      ]
    }
  ],
  extraLinks: [
    {
      text: "隐私政策",
      url: "/cn/privacy-policy/"
    },
    {
      text: "关于我们",
      url: "/cn/our-company/"
    },
    {
      text: "公司团队",
      url: "/cn/our-team/"
    },
    {
      text: "工作机会",
      url: "/cn/careers/"
    }
  ],
  regionsV4: [
    {
      country: "美国",
      code: "US",
      language: "English",
      url: "https://www.ff.com/us/"
    },
    {
      country: "中国",
      code: "CN",
      language: "中文",
      url: "https://www.faradayfuturecn.com/cn/"
    }
  ],
  version: "4"
};

export default footerPropsCN;
