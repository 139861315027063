import React from 'react';
import injectSheet from 'react-jss';
import _ from 'lib/gettext';
import Formsy from 'formsy-react';
import InputField from '~/app/components/FormFields/InputField';
import RadioSelects from '~/app/components/FormFields/RadioSelects';
import CheckSelect from '~/app/components/FormFields/CheckSelect';
import Button from '~/app/components/Button';
import BodyText from '~/app/components/typography/BodyText';

import EmailFieldValidation from '~/app/components/FormFields/EmailFieldValidation';
import withUser from '~/app/composers/withUser';

import InvitationCode from './InvitationCode';

import NameFields from './NameFields';
import PasswordFields from './PasswordFields';

class RSVPForm extends React.Component {
  state = {
    validForm: true
  }

  handleSubmit = () => {
    if (this.state.validForm) {
      this.form.submit();
    }
  }

  sanitizeUser = (user) => {
    const sanitizedUser = {
      firstName: user && user.firstName || '',
      lastName: user && user.lastName || '',
      mobile: user && user.mobile || '',
      zipcode: user && user.zipcode || '',
      email: user && user.email || '',
    };

    return sanitizedUser;
  }

  render() {
    const { classes: c, history, user } = this.props;
    const sanitizedUser = this.sanitizeUser(user);

    return (
      <div className={c.container}>
        <Formsy>
          <InvitationCode />
        </Formsy>
        <Formsy
          ref={(form) => { if (!this.form) { this.form = form; } }}
          onValidSubmit={this.handleSubmit}
          onInvalid={() => this.setState({ validForm: false })}
          onValid={() => this.setState({ validForm: true })}
          onInvalidSubmit={this.notifyFormError}
        >
          <NameFields firstName={sanitizedUser.firstName} lastName={sanitizedUser.lastName} />
          <EmailFieldValidation history={history} value={sanitizedUser.email} />
          <InputField
            name="mobile"
            fieldTitle={_('LABEL_PHONE_NUMBER')}
            validationErrors={{
              isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
            }}
            required
            placeholder="+###########"
            className={c.inputField}
            value={sanitizedUser.mobile}
          />
          <InputField
            name="zipcode"
            fieldTitle={_('LABEL_ZIP')}
            validationErrors={{
              isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
            }}
            required
            className={c.inputField}
            value={sanitizedUser.zipcode}
          />
          <RadioSelects
            options={[_('LABEL_PHONE'), _('LABEL_EMAIL')]}
            fieldTitle={_('LABEL_PREFERRED_CONTACT_METHOD')}
          />
          {!user && <PasswordFields />}
          <CheckSelect>
            <BodyText>{_('LABEL_SUBSCRIBE_PROMPT')}</BodyText>
          </CheckSelect>
          <CheckSelect>
            <BodyText>
              <span>
                {_('MESSAGE_AGREE_TO_FF')}&nbsp;
                <a href="/">{_('LABEL_TERMS_OF_SERVICES')}</a>
              </span>
            </BodyText>
          </CheckSelect>
          <Button
            handleClick={this.handleSubmit}
            disabled={!this.state.validForm}
            className={c.button}
          >
            {_('LABEL_CONTINUE')}
          </Button>
        </Formsy>
      </div>
    );
  }
}

const styles = {
  container: {
    maxWidth: '425px'
  },
  inputField: {
    marginBottom: '40px'
  },
  button: {
    marginTop: '40px',
    marginBottom: '40px'
  }
};

export default withUser(injectSheet(styles)(RSVPForm));
