import React from 'react';
import { AnalyticUtility } from '@ff/ui/utils/analyticUtils';

const analytics = new AnalyticUtility('CN');

export const AnalyticsContext = React.createContext({});

export function withAnalytics(Component) {
  return function AnalyticsComponent(props) {
    return (
      <AnalyticsContext.Consumer>
        {(analyticsImplementation) => (
          <Component {...props} analytics={analyticsImplementation} />
        )}
      </AnalyticsContext.Consumer>
    );
  };
}

export function AnalyticsProvider(props) {
  const { children } = props;

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  return analytics;
}
