import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useUser from '../hooks/queries/useUser';
import PATHS from '~/app/utils/paths';
import { getSameRegionPath as rp } from '~/app/utils/pathUtils';
import _ from 'lib/gettext';

export default function PrivateRoute({ component: Component, ...rest }) {
  const { loading, isAuthenticated } = useUser();

  if (loading) { return (<h1>{_('LABEL_LOADING')}</h1>); }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (isAuthenticated ? <Component {...props} /> : (
          <Redirect
            to={{
              pathname: rp(PATHS.LOGIN),
              state: { referrer: props.location }
            }}
          />
        ));
      }
      }
    />
  );
}
