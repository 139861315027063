import React from 'react';
import injectSheet from 'react-jss';
import _ from 'lib/gettext';
import BodyText from '~/app/components/typography/BodyText';
import BoxyRadio from '~/app/components/BoxyRadio';
import InputField from '~/app/components/FormFields/InputField';

class InvitationCode extends React.Component {
  state = {
    selectedIndex: -1
  }

  render() {
    const { classes: c } = this.props;
    const radioSelections = [_('LABEL_HAVE_CODE'), _('LABEL_NO_CODE')];
    // TODO: A few fields in the regex rule should be coming from env var.
    const inviteCodeRegex = /^FF91\-(\d{4})\-(\d{8})\-([a-zA-Z0-9]{2})\-(\d{4})$/mi; // eslint-disable-line no-useless-escape
    const codeField = this.state.selectedIndex === 0 && (
      <InputField
        name="inviteCode"
        validations={{
          matchRegexp: inviteCodeRegex
        }}
        validationErrors={{
          matchRegexp: _('ERROR_INVALID_INVITATION_CODE'),
          isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
        }}
        fieldTitle={_('LABEL_INVITATION_CODE')}
        placeholder="FF91-xxxx-xxxxxxxx-xx-xxxx"
      />
    );

    return (
      <div className={c.container}>
        <BodyText className={c.fieldTitle}>{_('LABEL_QUESION_HAVE_INVITATION_CODE')}</BodyText>
        <BoxyRadio
          fields={radioSelections}
          onChange={i => this.setState({ selectedIndex: i })}
          selectedIndex={this.state.selectedIndex}
          className={c.boxyRadio}
        />
        {codeField}
      </div>
    );
  }
}

const styles = {
  boxyRadio: {
    marginBottom: '15px'
  },
  fieldTitle: {
    fontWeight: '500',
    marginBottom: '10px'
  }
};

export default injectSheet(styles)(InvitationCode);
