import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '~/app/containers/PrivateRoute';
import PATHS from '~/app/utils/paths';
import HeaderFooterLayout from '~/app/layouts/HeaderFooterLayout';
import loadable from '@loadable/component';

const DesignDetails = loadable(() => import(/* webpackChunkName: "DesignDetails" */ '~/app/containers/Designs/Details'));

export default function DesignRouter() {
  return (
    <HeaderFooterLayout>
      <Switch>
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.DESIGN_DETAILS}`} component={DesignDetails} />
        <Redirect to="/:locale(us|cn)/404" />
      </Switch>
    </HeaderFooterLayout>
  );
}
