import React from 'react';
import PropTypes from 'prop-types';
import compose from 'lodash/fp/compose';
import { Modal } from 'antd';
import withUser from '~/app/composers/withUser';
import withVerifyEmail from '~/app/composers/withVerifyEmail';
import LoginFormUS from '~/app/containers/LoginPage/us/LoginFormUS';
import _ from 'lib/gettext';

class QueryLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    };
  }

  static propTypes = {
    fieldTitle: PropTypes.string.isRequired,
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { input: nextInput = '', loading: nextLoading, user: nextUser } = nextProps;
    const { input: currentInput = '', loading: currentLoading, user: currentUser } = this.props;
    const { isModalVisible } = nextState;
    const shouldUpdate = nextInput !== currentInput ||
      nextLoading !== currentLoading ||
      nextUser !== currentUser ||
      !isModalVisible;

    return shouldUpdate;
  }

  // TODO: To upgrade React llibrary to latest version to use getDerivedStateFromProps
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { didExist: nextDidExist, user } = nextProps;

    if (user) {
      this.setState({
        isModalVisible: false
      });
    } else {
      this.setState({
        isModalVisible: nextDidExist
      });
    }
  }

  closeModal = () => {
    this.setState({
      isModalVisible: false
    });
  }

  render() {
    const { loading, fieldTitle = '', history } = this.props;
    const { isModalVisible } = this.state;

    if (loading) {
      return (<div>Verifying the {fieldTitle}</div>);
    }

    return (
      <Modal
        title={<h1>{_('LABEL_LOGIN')}</h1>}
        visible={isModalVisible}
        footer={null}
        onCancel={this.closeModal}
      >
        <LoginFormUS history={history} />
      </Modal>
    );
  }
}

export default compose(
  withUser,
  withVerifyEmail,
)(QueryLoader);
