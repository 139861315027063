import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';
import Color from 'color';
import defaultStyles from '~/app/style/defaultStyles';

function Button({ className, classes: c, disabled, children, handleClick }) {
  return (
    <div
      className={cx(
        className, c.button, disabled && c.disabled)}
      onClick={handleClick}
    >
      <div>{children}</div>
    </div>
  );
}

const styles = {
  button: {
    width: '200px',
    height: '30px',
    color: 'white',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    cursor: 'pointer',
    backgroundColor: defaultStyles.TEST_RIDE_THEME,
    transition: 'all .2s ease-in-out',
    '&:hover': {
      backgroundColor: Color(defaultStyles.TEST_RIDE_THEME).lighten(0.2).string()
    },
    '@media(max-width: 479px)': {
      width: '100%'
    }
  },
  disabled: {
    backgroundColor: defaultStyles.DISABLED,
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: defaultStyles.DISABLED
    }
  }
};

export default injectSheet(styles)(Button);
