import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';

function BodyText({ className, classes: c, children }) {
  return (
    <p className={cx(className, c.p)}>{children}</p>
  );
}

const styles = {
  p: {
    fontSize: '15px',
    lineHeight: '2'
  }
};

export default injectSheet(styles)(BodyText);
