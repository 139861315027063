import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/fp/compose';
import query from '~/app/queries/user';

const mapDataToProps = (result) => {
  const {
    data: {
      user,
      loading,
      error
    },
  } = result;

  const isAuthenticated = !!(user && user.id);

  return {
    isAuthenticated,
    user,
    isFetchingUser: loading,
    errors: error
  };
};
const withUser = graphql(query, {
  props: mapDataToProps,
  options: (props) => {

    /* React Router uses/adds the match property directly to any Route component:
       https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Route.js#L118

       And computedMatch for any other React valid component
       https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Switch.js#L68
    */
    const region = props.match && props.match.params && props.match.params.locale && props.match.params.locale.toUpperCase() ||
      props.computedMatch && props.computedMatch.params && props.computedMatch.params.locale && props.computedMatch.params.locale.toUpperCase() ||
      props.logo_link === '/cn' ? 'CN' : 'US' ||
      'US';

    return {
      variables: {
        region
      }
    };
  }
});

export default compose(withUser);
