import React from 'react';
import injectSheet from 'react-jss';
import { Layout, Row, Col } from 'antd';
import _ from 'lib/gettext';
import AuthenticatedMenuBar from '~/app/containers/Genesis/Menu/AuthenticatedMenuBar';
import menuPropsCN from 'lib/menuPropsCN';
import Banner from '~/app/components/Banner';
import H1 from '~/app/components/typography/H1';
import BodyText from '~/app/components/typography/BodyText';
import DocContent from './DocContent';
import RSVPForm from './RSVPForm';

const { Content } = Layout;

function TestRidePage(props) {
  const { classes: c, history } = props;

  return (
    <div>
      <AuthenticatedMenuBar
        {...menuPropsCN}
        color_theme="DARK"
      />
      <Banner />
      <Content className={c.contentContainer}>
        <Row gutter={40}>
          <Col lg={16}>
            <H1>{_('LABEL_TEST_RIDE')}</H1>
            <BodyText>{_('MESSAGE_TEST_RIDE_DESCRIPTION')}</BodyText>
          </Col>
        </Row>
        <div className={c.main}>
          <Row gutter={40}>
            <Col lg={12}>
              <RSVPForm history={history} />
            </Col>
            <Col lg={10}>
              <DocContent />
            </Col>
          </Row>
        </div>
      </Content>
    </div>
  );
}

const styles = {
  contentContainer: {
    minHeight: '100vh',
    margin: 'auto',
    maxWidth: '1400px'
  },
  container: {
    backgroundColor: 'red'
  },
  main: {
    marginTop: '40px'
  }
};

export default injectSheet(styles)(TestRidePage);
