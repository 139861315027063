/* =============================================================================
=    Query Verify Email
============================================================================= */
import { gql } from '@apollo/client';

export default gql`
query verifyEmail($email: String!) {
  verifyEmail(email: $email) {
    _id
    didExist
  }
}
`;
