import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PATHS from '~/app/utils/paths';
import ReferralLandingLayout from '~/app/layouts/ReferralLandingLayout';
import { ReferralLanding } from './ReserveRouter';
import { ReserveCN } from './ReserveRouter';
import { ENABLE_NEW_REFERRAL } from '~/app/settings';

const ReferralPage = ENABLE_NEW_REFERRAL ? ReferralLanding : ReserveCN;

const ReferralRouter = () => {
  return (
    <ReferralLandingLayout>
      <Switch>
        <Route exact path={`/:locale(us|cn)${PATHS.REFERRAL}`}>
          {
            ({ match, history }) =>
              <ReferralPage
                match={match}
                history={history}
                isReferral
              />
          }
        </Route>
        <Redirect to="/:locale(us|cn)/404" />
      </Switch>
    </ReferralLandingLayout>
  );
};

export default ReferralRouter;
