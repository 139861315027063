import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';
import BodyText from '~/app/components/typography/BodyText';

class RadioSelects extends React.Component {
  state = {
    selected: 0
  }

  render() {
    const { classes: c, className, options, fieldTitle } = this.props;
    const { selected } = this.state;
    const title = fieldTitle && (
      <BodyText className={c.fieldTitle}>{fieldTitle}</BodyText>
    );
    const radio = (option, i) => (
      <div
        className={c.radioBtn}
        key={i}
        onClick={() => this.setState({ selected: i })}
      >
        <div className={cx(c.circle, selected === i && c.selected)} />
        <BodyText className={c.bold}>{option}</BodyText>
      </div>
    );

    return (
      <div className={className}>
        {title}
        {options.map((option, i) => radio(option, i))}
      </div>
    );
  }
}

const styles = {
  fieldTitle: {
    fontWeight: '500',
    marginBottom: '10px'
  },
  radioBtn: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  },
  bold: {
    fontWeight: '500',
    cursor: 'pointer'
  },
  circle: {
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    border: '2px solid #000',
    marginRight: '10px',
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'black',
      width: '12px',
      height: '12px',
      borderRadius: '12px',
      transform: 'scale(0.01)',
      left: '2px',
      top: '2px',
      transition: 'all .2s ease-in-out'
    }
  },
  selected: {
    '&::after': {
      transform: 'scale(1)'
    }
  }
};

export default injectSheet(styles)(RadioSelects);
