const menuPropsCN = {
  second_set: [
    {
      label: "登录",
      url: "/cn/MyFF/",
      loggedInLabel: "我的FF",
      subnav: []
    }
  ],
  first_set: [
    {
      subnav: [],
      label: "FF 91",
      url: "/cn/ff-91/"
    },
    {
      url: "/cn/configurator/",
      label: "定制",
      subnav: []
    },
    {
      url: "/cn/mobile-app/",
      label: "APP",
      subnav: []
    },
    {
      subnav: [],
      label: "投资关系",
      url: "/cn/investors/"
    }
  ],
  logo_link: "/cn",
  color_theme: "DARK",
  scroll_effect: true,
  hero_button: {
    url: "/cn/preorder/",
    label: "立即预订"
  },
  version: "2",
  drawerLinks: [
    {
      label: "主页",
      url: "/cn/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "FF 91 2.0 未来主义者联盟版",
      url: "/cn/product/ff-91-Alliance/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "FF 91 2.0 未来主义者版",
      url: "/cn/product/ff-91-Futurist/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "FF 91 2.0 ",
      url: "/cn/preorder?edition=ff91-standard",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "FF aiHypercar+",
      url: "/cn/ecosystem-product",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "",
      url: "",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "直播&活动",
      url: "/cn/91launch/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "新闻室",
      url: "/cn/press-room/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "投资者关系",
      url: "https://investors.ff.com/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "",
      url: "",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "联系我们",
      url: "/cn/contact-us/",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "商城",
      url: "https://shop.ff.com/pages/cn",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    },
    {
      label: "我的账户",
      url: "/cn/MyFF",
      loggedInLabel: "",
      loggedInLabelUrl: ""
    }
  ],
  logoType: "SIMPLE",
  transparent: true,
  float: true
}

export default menuPropsCN;