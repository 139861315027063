import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';

class CheckSelect extends React.Component {
  state = {
    selected: false
  }

  render() {
    const { classes: c, className, children } = this.props;

    return (
      <div
        className={cx(className, c.container)}
        onClick={() => this.setState(state => ({ selected: !state.selected }))}
      >
        <div className={cx(c.checkbox, this.state.selected && c.selected)} />
        <div className={c.text}>{children}</div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    width: '16px',
    height: '16px',
    borderRadius: '1px',
    border: '1px solid #000',
    marginRight: '10px',
    position: 'relative',
    cursor: 'pointer',
    '&::after': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'black',
      width: '10px',
      height: '10px',
      borderRadius: '1px',
      transform: 'scale(0.01)',
      left: '2px',
      top: '2px',
      transition: 'all .2s ease-in-out'
    }
  },
  selected: {
    '&::after': {
      transform: 'scale(1)'
    }
  },
  text: {
    cursor: 'pointer'
  }
};

export default injectSheet(styles)(CheckSelect);
