import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
// Components
import { AnalyticsProvider } from '~/app/components/Analytics';
import TestRidePage from '~/app/containers/TestRide/TestRidePage';
// Routers
import ReserveRouter from '~/app/containers/routers/ReserveRouter';
import AccountRouter from '~/app/containers/routers/AccountRouter';
import MyFFRouter from '~/app/containers/routers/MyFFRouter';
import ReferralRouter from '~/app/containers/routers/ReferralRouter';
import DesignRouter from '~/app/containers/routers/DesignRouter';
import SSORouter from '~/app/containers/routers/SSORouter';
// Utilities
import PATHS from '~/app/utils/paths';
import { getRegionPath } from '~/app/utils/pathUtils';
import ResetScroll from '~/app/utils/ResetScroll';
import loadable from '@loadable/component';

const HIDE_CONFIGURATOR = window._env_.REACT_APP_HIDE_CONFIGURATOR === 'true';

const Error404 = loadable(() => import(/* webpackChunkName: "Error404" */ '~/app/containers/Error404'));

const App = () => (
  <BrowserRouter>
    <ResetScroll />
    <AnalyticsProvider>
      <Helmet
        titleTemplate="%s | Faraday Future"
        defaultTitle="Faraday Future"
      />
      <Switch>
        <Route path="/:locale(us|cn)/404" component={Error404} />
        <Route path={`/:locale(us|cn)${PATHS.MY_FF}`} component={MyFFRouter} />
        <Route path={`/:locale(us|cn)${PATHS.ACCOUNT}`} component={AccountRouter} />
        <Route path={`/:locale(us|cn)${PATHS.RESERVE}`} component={ReserveRouter} />
        <Route path={`/:locale(us|cn)${PATHS.REFERRAL}`} component={ReferralRouter} />
        <Route path={`/:locale(us|cn)${PATHS.SSO}`} component={SSORouter} />
        {!HIDE_CONFIGURATOR && <Route path={`/:locale(us|cn)${PATHS.DESIGN}`} component={DesignRouter} />}
        <Route path={getRegionPath(PATHS.TEST_RIDE)} component={TestRidePage} />
        <Route path="*" component={Error404} />
      </Switch>
    </AnalyticsProvider>
  </BrowserRouter>
);

export default App;
