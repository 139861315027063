import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';

function BoxyRadio({ className, classes: c, fields, onChange, selectedIndex }) {
  const radioBtn = (fieldText, i) => (
    <div
      key={i}
      className={cx(c.radioBtn, i === selectedIndex && c.selected)}
      onClick={() => onChange(i)}
    >
      {fieldText}
    </div>
  );

  return (
    <div className={cx(className, c.container)}>
      {fields.length > 0 && fields.map((field, i) => radioBtn(field, i))}
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '@media(max-width: 476px)': {
      display: 'block'
    }
  },
  radioBtn: {
    border: '1px #ddd solid',
    textTransform: 'uppercase',
    width: '200px',
    height: '50px',
    lineHeight: '50px',
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'all .25s ease-in-out',
    '&:hover': {
      borderColor: '#333'
    },
    '@media(max-width: 476px)': {
      marginRight: '0',
      marginBottom: '15px'
    }
  },
  selected: {
    borderColor: '#333',
    borderWidth: '2px'
  }
};

export default injectSheet(styles)(BoxyRadio);
