import React, { Component } from 'react';
import { Form, Input, Button } from 'antd';
import join from 'lodash/join';
import withLoginAction from '~/app/composers/withLoginAction';
import { getSameRegionPath as rp } from '~/app/utils/pathUtils';
import PATHS from '~/app/utils/paths';
import ErrorBox from '~/app/utils/ErrorBox';
import _ from 'lib/gettext';
import Cookies from 'universal-cookie';
import { withAnalytics } from '~/app/components/Analytics';

const FormItem = Form.Item;

class LoginForm extends Component {
  loginAttempt = {
    email: '',
    counter: 5
  };
  state = {
    errors: [],
    waitingForServer: false
  }

  performLogin = (email, password, gaCookies) => {
    this.props.login(email, password, gaCookies)
      .then(() => {
        this.props.analytics.trackUIEvent('Login success');
      })
      .catch((error) => {

        const errors = error.graphQLErrors && error.graphQLErrors.map((err) => {
          if (err.message === 'ERROR_CODE_3') {
            if (this.loginAttempt.email === email) {
              this.loginAttempt.counter --;
              if (this.loginAttempt.counter < 0) {
                this.loginAttempt.counter = 0;
              }

            } else {
              this.loginAttempt = {
                email,
                counter: 4
              };
            }
          } else if (err.message.includes('request to http')) {
            return _('ERROR_SERVER');
          }

          return `${_(err.message)}`;
        });

        this.props.analytics.trackUIEventError('Login error', join(errors, ', '));
        this.setState({
          errors,
          waitingForServer: false
        });
        this.ErrorBox.show();
      });
  }

  submitClick = (values) => {
    this.ErrorBox.close();

    this.setState({ waitingForServer: true }, () => {
      this.performLogin(values.email, values.password, (new Cookies()).get('_ga') || '');
    });
  }

  handleForgotPass = () => {
    this.props.history.push(rp(PATHS.FORGOT_PASS));
  }

  renderButtons = () => {
    if (this.state.waitingForServer) {
      return (
        <FormItem>
          <Button size='large' className="disabled">
            {_('PLEASE_WAIT')}
          </Button>
          <Button size='large' className="inverted disabled">
            {_('LABEL_FORGOT_PASSWORD')}
          </Button>
        </FormItem>
      );
    }

    return (
      <FormItem>
        <Button size='large' htmlType="submit" disabled={this.loginAttempt.counter === 0}>
          {_('LABEL_LOGIN')}
        </Button>
        <Button size='large' className="inverted" onClick={this.handleForgotPass}>
          {_('LABEL_FORGOT_PASSWORD')}
        </Button>
      </FormItem>
    );
  }

  render() {
    const errorMessage = (<div>
      {this.state.errors}{_('MARK_PERIOD')}
      {this.loginAttempt.counter < 5 &&
        <span>
          {this.loginAttempt.counter} {_('MESSAGE_MORE_ATTEMPTS_LOGIN')} {_('MESSAGE_RESET_PASSWORD')}
          &nbsp;<a onClick={this.handleForgotPass}>{_('LABEL_HERE')}</a>{_('MARK_PERIOD')}
        </span>}
    </div>
    );

    return (
      <Form onFinish={this.submitClick} className="login-form">
        <FormItem
          name='email'
          validateTrigger='onBlur'
          rules={[
            { type: 'email', message: _('ERROR_VALID_EMAIL') },
            { required: true, message: _('ERROR_EMPTY_EMAIL') }
          ]}
        >
          <Input size='large' placeholder={`${_('LABEL_EMAIL')}*`} />
        </FormItem>
        <FormItem
          name='password'
          rules={[{ required: true, message: _('ERROR_EMPTY_PASSWORD') }]}
        >
          <Input size='large' type="password" placeholder={`${_('LABEL_PASSWORD')}*`} />
        </FormItem>

        <ErrorBox content={errorMessage} ref={(error) => { this.ErrorBox = error; }} />
        {this.renderButtons()}
      </Form>
    );
  }
}

export default withAnalytics(withLoginAction(LoginForm));
