import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/fp/compose';
import query from '~/app/queries/user';
import mutation from '~/app/mutations/login';

const mapActionToProps = ({ mutate }) => ({
  login: (email, password, gaCookies) => mutate({
    variables: { email, password, gaCookies },
    refetchQueries: [
      {
        query,
        variables: {
          region: 'US'
        }
      }
    ]
  })
});
const withLoginAction = graphql(mutation, {
  props: mapActionToProps
});

export default compose(withLoginAction);
