import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PATHS from '~/app/utils/paths';
import HeaderFooterLayout from '~/app/layouts/HeaderFooterLayout';
import loadable from '@loadable/component';

const SSORedirectPage = loadable(() => import(/* webpackChunkName: "SSORedirectPage" */ '~/app/containers/LoginPage/SSORedirectPage'))
const PhoneBindingPage = loadable(() => import(/* webpackChunkName: "SSOPhoneBindingPage" */ '~/app/containers/LoginPage/PhoneBindingPage'));

const SSORouter = () => {
  return (
    <HeaderFooterLayout skipUserAuthentication>
      <Switch>
        {/* SSO callback */}
        <Route exact path={`/:locale(us|cn)(${PATHS.SSO_CALLBACK_APPLE}|${PATHS.SSO_CALLBACK_WECHAT}|${PATHS.SSO_CALLBACK_SINAWEIBO})`} component={SSORedirectPage} />
        {/* SSO phone binding */}
        <Route exact path={`/:locale(us|cn)(${PATHS.SSO_PHONE_APPLE}|${PATHS.SSO_PHONE_WECHAT}|${PATHS.SSO_PHONE_SINAWEIBO})`} component={PhoneBindingPage} />
        <Redirect to="/:locale(us|cn)/404" />
      </Switch>
    </HeaderFooterLayout>
  );
};


export default SSORouter;
