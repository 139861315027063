import React from 'react';
import injectSheet from 'react-jss';
import _ from 'lib/gettext';
import InputField from '~/app/components/FormFields/InputField';

function NameFields({ classes: c, firstName = '', lastName = '' }) {
  return (
    <div className={c.container}>
      <InputField
        name="firstName"
        validations="isExisty"
        validationErrors={{
          isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
        }}
        required
        fieldTitle={_('LABEL_FIRST_NAME')}
        containerClass={c.inputField}
        value={firstName}
      />
      <InputField
        name="lastName"
        validations="isExisty"
        validationErrors={{
          isDefaultRequiredValue: _('ERROR_REQUIRED_FIELD')
        }}
        required
        fieldTitle={_('LABEL_LAST_NAME')}
        value={lastName}
      />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
    '@media(max-width: 476px)': {
      display: 'block',
    }
  },
  inputField: {
    '@media(max-width: 476px)': {
      marginBottom: '40px'
    }
  }
};

export default injectSheet(styles)(NameFields);
