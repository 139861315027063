import React from 'react';
import injectSheet from 'react-jss';
import cx from 'classnames';
import { withFormsy } from 'formsy-react';
import BodyText from '~/app/components/typography/BodyText';
import defaultStyles from '~/app/style/defaultStyles';

class InputField extends React.Component {
  state = {
    blurred: false
  }

  handleBlur = () => {
    this.setState({
      blurred: true,
    });
  }

  handleFocus = () => {
    this.setState({
      blurred: false,
    });
  }

  render() {
    const { classes: c, placeholder, fieldTitle, className } = this.props;
    const errors = this.state.blurred && this.props.getErrorMessage();
    const title = fieldTitle && (
      <BodyText className={c.fieldTitle}>{fieldTitle}</BodyText>
    );

    return (
      <div className={className}>
        {title}
        <input
          placeholder={placeholder}
          type="text"
          onChange={e => this.props.setValue(e.target.value)}
          className={cx(c.input, errors && c.hasError)}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          value={this.props.getValue() || ''}
        />
        <BodyText className={c.error}>{errors}</BodyText>
      </div>
    );
  }
}
const styles = {
  fieldTitle: {
    fontWeight: '500',
    marginBottom: '10px'
  },
  input: {
    outline: 'none',
    height: '45px',
    border: '1px solid #ccc',
    borderRadius: '2px',
    padding: '4px 10px',
    fontWeight: '300',
    width: '100%',
    minWidth: '200px'
  },
  hasError: {
    borderColor: defaultStyles.ERROR_COLOR
  },
  error: {
    color: defaultStyles.ERROR_COLOR,
    fontWeight: '400'
  }
};

export default withFormsy(injectSheet(styles)(InputField));
