import React from 'react';
import _ from 'lib/gettext';
import BodyText from '~/app/components/typography/BodyText';
import ExpansionPanel from '~/app/components/ExpansionPanel';

function DocContent() {
  return (
    <div>
      <ExpansionPanel title={_('LABEL_TEST_RIDE_POLICY')}>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
      </ExpansionPanel>
      <ExpansionPanel title={_('LABEL_PLACEHOLDER')}>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
        <BodyText>{_('PLACEHOLDER')}</BodyText>
      </ExpansionPanel>
    </div>
  );
}

export default DocContent;
