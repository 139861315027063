import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from '~/app/containers/PrivateRoute';
import PATHS from '~/app/utils/paths';
import loadable from '@loadable/component';
import HeaderFooterLayout from '~/app/layouts/HeaderFooterLayout';

const HIDE_CONFIGURATOR = window._env_.REACT_APP_HIDE_CONFIGURATOR === 'true';
const ENABLE_NEW_ECOSYSTEM_PRODUCT = window._env_.REACT_APP_ENABLE_NEW_ECOSYSTEM_PRODUCT === 'true';

const MyFF = loadable(() => import(/* webpackChunkName: "MyFF" */ '~/app/containers/MyFF'));
const MyAccount = loadable(() => import(/* webpackChunkName: "AccountPage" */ '~/app/containers/AccountPage'));
const OrderList = loadable(() => import(/* webpackChunkName: "OrderList" */ '~/app/containers/Reserve/OrderList'));
const FFPoints = loadable(() => import(/* webpackChunkName: "FFPoints" */ '~/app/pages/RewardPointsPage'));
const DesignList = loadable(() => import(/* webpackChunkName: "DesignList" */ '~/app/containers/Designs/DesignList'));
const ReferrerProgram = loadable(() => import(/* webpackChunkName: "ReferrerProgram" */ '~/app/pages/ReferralPage/ReferralProgram'));
const MyReservationsCN = loadable(() => import(/* webpackChunkName: "MyReservationsCN" */ '~/app/containers/EcoProductPage/MyReservationsCN'));
const EcoProductDetailsPage = loadable(() => import(/* webpackChunkName: "EcoProductDetailsPage" */ '~/app/containers/EcoProductPage/EcoProductDetailsPage'));

const MyFFRouter = () => {
  return (
    <HeaderFooterLayout>
      <Switch>
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_FF}`} component={MyFF} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_ACCOUNT}`} component={MyAccount} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_RESERVATIONS}`} component={OrderList} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_REFERRALS}`} component={ReferrerProgram } />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_FF_POINTS}`} component={FFPoints} />
        {ENABLE_NEW_ECOSYSTEM_PRODUCT && <PrivateRoute exact path={`/:locale(us|cn)${PATHS.ECOSYSTEM_PRODUCT_DETAILS}`} component={EcoProductDetailsPage} />}
        {ENABLE_NEW_ECOSYSTEM_PRODUCT && <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_ECOSYSTEM_PRODUCT}`} component={MyReservationsCN} />}
        {!HIDE_CONFIGURATOR && <PrivateRoute exact path={`/:locale(us|cn)${PATHS.MY_DESIGNS}`} component={DesignList} />}
        <Redirect to="/:locale(us|cn)/404" />
      </Switch>
    </HeaderFooterLayout>
  );
};


export default MyFFRouter;
