import React from 'react';
import injectSheet from 'react-jss';
import Header2 from '@ff/ui/Header2';
import PropTypes from 'prop-types';

class MenuBar extends React.Component {
  render() {
    const {
      classes: c,
      isLoggedIn,
      logo_link
    } = this.props;

    return (
      <div className={c.container}>
        <Header2
         {...this.props} 
         logoLink={logo_link}
         isLoggedIn={isLoggedIn} 
         color_theme="LIGHT" 
         transparent={false} 
         scrollEffect={false} 
         float={false} 
        />
      </div>
    );
  }
}

MenuBar.propTypes = {
  logo_link: PropTypes.string,
  first_set: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    loggedInLabel: PropTypes.string,
  })),
  second_set: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    loggedInLabel: PropTypes.string,
  })),
  hero_button: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    loggedInLabel: PropTypes.string,
  }),
  color_theme: PropTypes.string,
  scroll_effect: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  hasAppointments: PropTypes.bool,
  hasOrders: PropTypes.bool,
  isFetchingUser: PropTypes.bool
}

const styles = {
  container: {
    // zIndex: 1051, // antd drop down is 1050
    zIndex: 10, // temporarily fix for hide hamburger menu on open
    position: 'relative',
    '& a:hover': {
      color: 'inherit',
    }
  }
};


export default injectSheet(styles)(MenuBar);
