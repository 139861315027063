/* =============================================================================
=    Login
============================================================================= */
import { gql } from '@apollo/client';

export default gql`
mutation login($email: String!, $password: String!, $gaCookies: String!) {
  login(email: $email, password: $password, gaCookies: $gaCookies) {
    id
  }
}
`;

export const loginCN = gql`
mutation loginCN($mobile: String!, $password: String, $captcha: String) {
  loginCN(mobile: $mobile, password: $password, captcha: $captcha) {
    id
  }
}
`;
