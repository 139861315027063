import React from 'react';
import injectSheet from 'react-jss';
import FFFooter3 from '@ff/ui/Footer3';

class Footer extends React.Component {
  render() {
    return (
      <div className={this.props.classes.container}>
        <FFFooter3 {...this.props} regions={this.props.version == 4 ? this.props.regionsV4 : this.props.regions} />
      </div>
    );
  }
}

const styles = {
  container: {
    '& a:hover': {
      color: 'white',
    }
  },
};

export default injectSheet(styles)(Footer);

