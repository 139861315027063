import { graphql } from '@apollo/client/react/hoc';
import compose from 'lodash/fp/compose';
import query from '~/app/queries/verifyEmail';

const mapDataToProps = (result) => {
  let didExist = false;

  if (result.data.verifyEmail && result.data.verifyEmail.didExist) {
    const { didExist: didExistValue } = result.data.verifyEmail;

    didExist = didExistValue;
  }

  return {
    didExist,
    loading: result.data.loading,
    errors: result.data.error
  };
};

const withVerifyEmail = graphql(query, {
  options: ({ input }) => ({ variables: { email: input }}),
  props: mapDataToProps
});

export default compose(withVerifyEmail);
