const PATHS = {
  US_BASE: '/us',
  CN_BASE: '/cn',
  MY_FF: '/MyFF',
  // Account info page
  ACCOUNT: '/account',
  SSO: '/sso',
  MY_ACCOUNT: '/MyFF/account',
  MY_RESERVATIONS: '/MyFF/preorders',
  MY_REFERRALS: '/MyFF/referrals',
  MY_FF_POINTS: '/MyFF/points',
  MY_DESIGNS: '/MyFF/designs',
  // Login / sign up page
  LOGIN: '/account/login',
  // Welcome, successful login
  SUCCESS: '/account/success',
  // Change phone
  CHANGE_PHONE: '/account/change-phone',
  // Change password
  CHANGE_PASS: '/account/change-password',
  // Forgot password page
  FORGOT_PASS: '/account/forgot-password',
  // Reserve Step 2 (Standard/Alliance) http://10.0.0.203:8080/?page_id=64998
  RESERVE: '/preorder',
  // Reserve Step 3 (Billing info) http://10.0.0.203:8080/?page_id=65026
  PREORDER: '/reserve/preorder',
  PREORDER_STANDARD: '/reserve/preorder/standard',
  PREORDER_PRIORITY: '/reserve/preorder/priority',
  // Thank you for order
  RESERVE_SUCCESS_BASE: '/preorder/success',
  RESERVE_SUCCESS: '/preorder/success/:confirmationNum',
  // Reservation detailed view
  RESERVE_DETAILS: '/preorder/details/:confNum',
  RESERVE_DETAILS_BASE: '/preorder/details',
  DESIGN_DETAILS: '/design/details/:confNum',
  DESIGN_DETAILS_BASE: '/design/details/',
  DESIGN: '/design',
  CONFIGURATOR: '/configurator',
  // Make payment page
  MAKE_PAYMENT: '/preorder/make-payment',
  // Show payment limitation table
  PAYMENT_LIMITATION_BASE: '/preorder/payment-limitation',
  PAYMENT_LIMITATION: '/preorder/payment-limitation/:method',
  ALIPAY: '/alipay',
  WXPAY: '/wxpay',
  // My Referrals
  REFERRAL: '/referral/:referralCode',

  // Test ride
  TEST_RIDE: '/test-ride',

  toCN: '/cn/MyFF',
  toUS: '/us/MyFF',
  PRIVACY: '/cn/privacy-policy',
  REGISTER_AGREEMENT: '/cn/ff-account-registration-agreement',
  UNREGISTER_AGREEMENT: '/ff-account-unregistration-agreement',
  FPO_AGREEMENT: '/cn/fpo-registration-policy',
  TERMS_OF_USE: '/us/terms-of-use',
  REZ_AGREEMENT: '/us/preorder-agreement',
  REZ_AGREEMENT_CN: '/cn/preorder-agreement',
  MAIL_PRODUCT: 'mailto:product@ff.com',
  MAIL_CN_USER: 'mailto:cn-user@ff.com',
  ECOPRODUCT_AGREEMENT: '/cn/aiHypercar-preorder-agreement',

  // referral
  REFERRAL_PROGRAM_TERMS: '/cn/employee-referral-program-terms',
  REFERRAL_PROGRAM_LEARN_MORE: '/cn/referral-learn-more/',
  REFERRAL_PROGRAM_AGREEMENT: '/cn/referral-agreement/',

  VERIFY_EMAIL: '/verify-email/:email',
  APP_RESERVE: '/h5/reserve',
  APP_LEARN_MORE: '/h5/reserve/learn-more',
  APP_USER_INFO: '/h5/reserve/user-info',
  APP_RESERVATION_AGREEMENT: '/h5/reserve/reservation-agreement',
  APP_ECOPRODUCT_AGREEMENT: '/h5/reserve/aiHypercar-preorder-agreement',

  // EcoSystem Product
  ECOSYSTEM_PRODUCT_PAGE: '/cn/ecosystem-product/',
  MY_ECOSYSTEM_PRODUCT: '/MyFF/ecosystem-product',
  ECOSYSTEM_PRODUCT_DETAILS: `/MyFF/ecosystem-product/details/:itemId`,
  ECOSYSTEM_PRODUCT_PREORDER: '/preorder/ecosystem-product-preorder',
  APP_ECOSYSTEM_PRODUCT_DETAILS: `/h5/ecosystem-product-details/:itemId`,
  APP_ECOSYSTEM_PRODUCT_PREORDER: `/h5/ecosystem-product-preorder`,

  // SSO callback
  SSO_CALLBACK_WECHAT: '/sso/wechat/callback',
  SSO_CALLBACK_SINAWEIBO: '/sso/sina-weibo/callback',
  SSO_CALLBACK_APPLE: '/sso/apple/callback',

  // SSO phone binding
  SSO_PHONE_WECHAT: '/sso/phone/wechat',
  SSO_PHONE_SINAWEIBO: '/sso/phone/sina-weibo',
  SSO_PHONE_APPLE: '/sso/phone/apple',

  // FF 91 Configurator
  FF91_FUTURIST_PRODUCT: '/cn/product/ff-91-Futurist/',
  FF91_FUTURIST_ALLIANCE_PRODUCT: '/cn/product/ff-91-Alliance/',
  FF91_STANDARD_PREORDER: '/cn/preorder/ff-91',
  FF91_CONFIGURATOR_OPTIONS: '/cn/design/2022/FF91/options'

};

export default PATHS;
