import React, { Component } from 'react';
import { Alert } from 'antd';
import _ from 'lib/gettext';

class ErrorBox extends Component {
  state = {
    visible: false
  }

  show = () => {
    this.setState({ visible: true });
  }

  close = () => {
    this.setState({ visible: false });
  }

  render() {
    if (this.state.visible || this.props.visible) {
      const err = this.props.content;

      return (
        <Alert
          message={_('LABEL_ERROR')}
          description={err}
          type="error"
          closable
          onClose={() => { window.setTimeout(this.close, 500); }}
        />
      );
    }

    return (null);
  }
}

export default ErrorBox;
