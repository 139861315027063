import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'isomorphic-fetch';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import App from '~/app/containers/App';

import 'normalize.css';
// Bring customized styles after 'App' to override ant defaults
import './app/style/index.less';
import { DefaultThemeProvider } from '@ff/ui';

const client = new ApolloClient({
  link: createHttpLink({
    uri: '/graphql',
    credentials: 'same-origin',
    headers: { region: 'CN', source: 'Web' }
  }),
  cache: new InMemoryCache(),
});

/* =============================================================================
=    Apollo
============================================================================= */
const app = (
  <DefaultThemeProvider>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </DefaultThemeProvider>
);
const rootElement = document.getElementById('app');

ReactDOM.render(app, rootElement);
