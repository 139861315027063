import React from 'react';
import injectSheet from 'react-jss';
import VideoPlayer from './VideoPlayer';

function Banner({ className, classes: c }) {
  return (
    <div className={className}>
      <div className={c.video}>
        <VideoPlayer
          coverLink="https://genesis-cdn.ff.com/homepage/01_marquee/genesis_cover_image.jpg"
          videoUrl="https://genesis-cdn.ff.com/homepage/01_marquee/genesis_ff91_emergence_3mbps.mp4"
          videoPlayPrefereces={{
            autoplay: false,
            muted: true,
            loop: true
          }}
          playControl
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%'
  },
  video: {
    width: '100%',
    height: '400px',
    '@media(min-width: 1400px)': {
      height: '600px'
    }
  }
};

export default injectSheet(styles)(Banner);
