import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PrivateRoute from '~/app/containers/PrivateRoute';
import PATHS from '~/app/utils/paths';
import { getSameRegionPath as rp } from '~/app/utils/pathUtils';
import HeaderFooterLayout from '~/app/layouts/HeaderFooterLayout';
import loadable from '@loadable/component';

const LoginPage = loadable(() => import(/* webpackChunkName: "LoginPage" */ '~/app/containers/LoginPage'));
const ForgotPassword = loadable(() => import(/* webpackChunkName: "ForgotPassword" */ '~/app/containers/ForgotPassword'));
const ResetPass = loadable(() => import(/* webpackChunkName: "Page" */ '~/app/containers/ResetPassword/Page'));
const LoginSuccess = loadable(() => import(/* webpackChunkName: "Success" */ '~/app/containers/LoginPage/Success'));
const ChangePhone = loadable(() => import(/* webpackChunkName: "ChangePhone" */ '~/app/containers/ChangePhone'));
const ChangePass = loadable(() => import(/* webpackChunkName: "ChangePassword" */ '~/app/containers/ChangePassword'));

const AccountRouter = () => {
  return (
    <HeaderFooterLayout>
      <Switch>
        <Route exact path={`/:locale(us|cn)${PATHS.LOGIN}`} component={LoginPage} />
        <Route exact path={`/:locale(us|cn)${PATHS.FORGOT_PASS}`} component={ForgotPassword} />
        <Route exact path={`/:locale(us|cn)${PATHS.RESET_PASS}`} component={ResetPass} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.SUCCESS}`} component={LoginSuccess} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.CHANGE_PHONE}`} component={ChangePhone} />
        <PrivateRoute exact path={`/:locale(us|cn)${PATHS.CHANGE_PASS}`} component={ChangePass} />
        {/* Redirect for legacy My Account - TODO: remove at some point */}
        <Route exact path={`/:locale(us|cn)${PATHS.ACCOUNT}`}>
          <Redirect to={rp(PATHS.MY_FF)} />
        </Route>
        <Redirect to="/:locale(us|cn)/404" />
      </Switch>
    </HeaderFooterLayout>
  );
};


export default AccountRouter;
